import { StyleSheet, Platform } from "react-native";
import Colors from "../../constants/Colors";
import COLOR from "../../constants/Colors";

// FONT
const FONTSIZE_24 = 24;
const FONTSIZE_17 = 17;
const FONTSIZE_16 = 16;
const FONTSIZE_14 = 14;
const FONTSIZE_12 = 12;
const FONTSIZE_10 = 10;
const FONTWEIGHT_300 = "300";
const FONTWEIGHT_400 = "400";
const FONTWEIGHT_500 = "500";
const FONTWEIGHT_600 = "600";
const FONTWEIGHT_700 = "700";

export default StyleSheet.create({
  container: {
    paddingTop: 50,
  },
  tinyLogo: {
    width: "100%",
    height: 67.37,
    resizeMode: "contain",
  },
  banner: {
    height: 243,
  },
  coreBanner: {
    height: 180,
  },
  dropoffSection: {
    padding: 12,
    alignItems: "center",
    borderRadius: 15,
    marginTop: 10,
    marginRight: 15
  },
  dropoffItembutton: {
    color: Colors.WHITE,
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_700,
  },
  input: {
    height: 60,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "transparent",
    fontWeight: FONTWEIGHT_400,
    fontSize: FONTSIZE_14,
    color: COLOR.GRAY,
  },
  butttonOkData: {
    width: 50,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 10,
    textAlign: "center",
    fontWeight: FONTWEIGHT_700,
    borderColor: COLOR.BROWN,
  },
  mainTitle: {
    fontWeight: FONTWEIGHT_500,
    fontSize: FONTSIZE_24,
    color: COLOR.GRYSIX,
    lineHeight: 32,
  },
  manageTitle: {
    fontSize: FONTSIZE_17,
    color: COLOR.GRAY,
    lineHeight: 25,
    textAlign: "justify",
  },
  btnsection: {
    marginHorizontal: 20,
    marginBottom: 10,
  },
  footerButtonSection: {
    marginBottom: 55,
  },
  button: {
    height: 56,
    borderRadius: 12,
    paddingVertical: 12,
    backgroundColor: COLOR.BACKGROUND_COLOR_RUBY,
  },
  signUpButton: {
    height: 56,
    borderRadius: 12,
    paddingVertical: 12,
    backgroundColor: COLOR.BACKGROUND_COLOR_RUBY,
    marginTop: 16,
  },
  btntextstyle: {
    color: COLOR.WHITE,
    textAlign: "center",
    lineHeight: 40,
    fontSize: FONTSIZE_16,
    fontWeight: FONTWEIGHT_600,
  },
  subTitle: {
    fontSize: FONTSIZE_16,
    color: COLOR.DARK_GRAY,
    lineHeight: 24,
    fontWeight: FONTWEIGHT_400,
  },
  justifyContent: {
    justifyContent: "center",
  },
  alignItems: {
    alignItems: "center",
  },
  error: {
    color: COLOR.RED,
    fontSize: 12,
    marginTop: 2,
  },
  inputFiledstyle: {
    height: 60,
    borderRadius: 12,
    backgroundColor: COLOR.WHITE,
    borderTopEndRadius: 12,
    borderTopStartRadius: 12,
    overflow: "hidden",
    borderWidth: 1,
    borderStyle: "solid",
    paddingHorizontal: 24,
    borderColor: "rgba(15, 15, 15, 0.12)",
  },
  disableButton: {
    height: 56,
    borderRadius: 12,
    paddingVertical: 12,
    opacity: 0.7,
  },
  disableBtnStyle: {
    color: "#DDDDDD",
    textAlign: "center",
    lineHeight: 34,
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_600,
  },
  // ----LandingCritera
  Landingcontainer: {
    flex: 1,
    color: COLOR.GRYSIX,
    width: "100%",
  },
  containerBody: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 20,
  },
  FlexContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  contentTitle: {
    borderBottomWidth: 1,
    borderBottomColor: COLOR.LIGHTEN_GRAY,
    width: "100%",
  },
  contentHeader: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 64,
  },
  contentHeaderBody: {
    maxWidth: 302,
  },
  cardContainer: {
    margin: 20,
    marginTop: 64,
  },
  cardBody: {
    marginBottom: 6,
  },
  icon: {
    paddingRight: 20,
  },
  cardStyle: {
    backgroundColor: "#F9F6F1",
    paddingHorizontal: 24,
    paddingVertical: 20,
    borderRadius: 12,
    shadowColor: "transparent",
  },
  headerCardStyle: {
    paddingHorizontal: 32,
    paddingVertical: 15,
    borderRadius: 12,
    shadowColor: "transparent",
  },
  headerTitle: {
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.GRYSIX,
    lineHeight: 24,
  },
  headerSubTitle: {
    fontSize: FONTSIZE_12,
    fontWeight: FONTWEIGHT_400,
    color: COLOR.GRYSIX,
  },
  subtitleStyle: {
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_400,
    color: COLOR.DARK_GRAY,
  },
  createAcoontText: {
    fontWeight: "bold",
    color: COLOR.BLACK,
  },
  cardTitleStyle: {
    minHeight: 0,
    paddingLeft: 0,
  },
  leftStyle: {
    paddingRight: 0,
    marginRight: -5,
  },
  otherLeftStyle: {
    paddingRight: 0,
    marginRight: -5,
  },

  // // ----AdditemCart---
  containerHeader: {
    alignItems: "center",
    paddingVertical: 32,
    flexDirection: "row",
    paddingHorizontal: 20,
    justifyContent: "space-between",
  },
  progress: {
    width: 120,
    height: 6,
    borderRadius: 8,
  },
  progressSteps: {
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.BLACK,
    lineHeight: 24,
  },
  horizontalRule: {
    marginBottom: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLOR.LIGHTEN_GRAY,
    width: "100%",
    marginBottom: 28,
  },
  Title: {
    fontSize: FONTSIZE_24,
    paddingTop: 40,
    fontWeight: FONTWEIGHT_500,
  },
  block: {
    minHeight: 230,
  },
  cardContainertitle: {
    marginHorizontal: 20,
  },

  //  ---AddItem---
  AddcardContainer: {
    // marginTop: 84,
  },
  cardTitle: {
    justifyContent: "space-between",
    flexDirection: "row",
    maxWidth: 302,
    fontSize: FONTSIZE_14,
  },
  carddropitem: {
    fontWeight: FONTWEIGHT_600,
    lineHeight: 24,
    marginBottom: 4,
  },
  cardHeader: {
    fontWeight: FONTWEIGHT_600,
    fontSize: FONTSIZE_14,
    lineHeight: 28,
  },
  cardContent: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  Maincard: {
    marginHorizontal: 20,
    marginVertical: 6,
    backgroundColor: "#F9F6F1",
    borderRadius: 12,
    shadowColor: "transparent",
  },
  cardsubtitle: {
    height: 28,
    justifyContent: "center",
    backgroundColor: COLOR.WHITE,
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
    shadowColor: COLOR.BLACK,
    elevation: 24,
  },
  cardsubtitleTextStyle: {
    overflow: "hidden",
    borderRadius: 8,
    fontSize: FONTSIZE_12,
    fontWeight: FONTWEIGHT_600,
  },
  sektext: {
    color: "rgba(0, 0, 0, 0.4)",
    textTransform: "uppercase",
    paddingLeft: 4,
  },
  CardParagraph: {
    fontSize: 13,
    lineHeight: 20,
    color: COLOR.DARK_GRAY,
  },
  horizontalRole: {
    borderBottomWidth: 1,
    borderBottomColor: COLOR.LIGHTEN_GRAY,
    width: "100%",
  },
  indiskatext: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 84,
  },
  btnSection: {
    marginHorizontal: 20,
    marginBottom: 20,
    marginTop: 18,
  },

  //landignCriteriaNotAccept.js
  landingback: {
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 38,
    marginTop: 32,
  },
  crossIconSection: {
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 44,
  },
  closeCircle: {
    marginLeft: 5,
    marginTop: 40,
  },
  acceptCardBody: {
    marginHorizontal: 20,
    marginTop: 24,
  },
  acceptCardStyle: {
    backgroundColor: COLOR.LIGHT_YELLOW,
    borderRadius: 12,
    shadowColor: "transparent",
    paddingBottom: 20,
  },
  cardCoverStyle: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  acceptSubtitleStyle: {
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.DARK_GRAY,
  },

  // //  -------Additemopetion------
  optionBody: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 63,
  },
  titleContainer: {
    maxWidth: 302,
  },
  mainContent: {
    paddingHorizontal: 20,
    width: "100%",
    paddingTop: 55,
  },
  listitems: {
    backgroundColor: COLOR.LIGHT_YELLOW,
    borderRadius: 12,
    paddingHorizontal: 24,
    marginTop: 15,
  },
  newBrandData: {
    backgroundColor: "#F9F6F1",
    borderRadius: 12,
    paddingHorizontal: 24,
    lineHeight: 24,
    paddingVertical: 18,
    color: Colors.BLACK,
    fontWeight: "bold",
  },
  categorieData: {
    lineHeight: 24,
    paddingVertical: 20,
    color: Colors.BLACK,
    fontWeight: "bold",
  },
  accordion: {
    backgroundColor: "#EBE9E6",
    paddingHorizontal: 20,
    borderRadius: 12,
    overflow: "hidden",
  },
  accordionTitle: {
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.BLACK,
  },
  AddcontainerBody: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 63,
  },

  // // ------------AddItemsearch------
  titlesearchContainer: {
    paddingHorizontal: 20,
  },
  searchmainContent: {
    paddingHorizontal: 20,
    paddingTop: 36,
  },
  searchDropIterm: {
    paddingBottom: 24,
    paddingTop: 12,
  },
  searchBar: {
    borderRadius: 12,
    shadowColor: "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: COLOR.LIGHT_WHITE,
    paddingHorizontal: 15,
    height: 56,
  },
  searchInput: {
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_500,
    paddingLeft: 0,
    position: "relative",
    right: 4,
  },
  titleStyle: {
    paddingHorizontal: 24,
    marginTop: 24,
    marginBottom: 12,
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.GRAY,
    lineHeight: 24,
  },

  // // --------AddItemSucess------
  successimageContainer: {
    height: 172,
    width: 172,
    backgroundColor: COLOR.LIGHT_YELLOW,
    borderRadius: 172,
    justifyContent: "center",
  },
  imgbanner: {
    width: 96,
    height: 71,
    resizeMode: "contain",
    alignSelf: "center",
    marginLeft: 10,
  },
  titleHeader: {
    fontWeight: FONTWEIGHT_500,
    fontSize: FONTSIZE_24,
    marginTop: 21,
    color: COLOR.GRYSIX,
    textAlign: "center",
  },
  AddsubTitle: {
    fontSize: FONTSIZE_14,
    marginTop: 12,
    color: COLOR.DARK_GRAY,
    lineHeight: 24,
    textAlign: "center",
  },
  buttonContainer: {
    marginHorizontal: 20,
    marginBottom: 34,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  blockdata: {
    height: 161,
  },

  // //  -----DropOffitem-------
  dropitems: {
    marginTop: 40,
    marginHorizontal: 20,
  },
  productContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 32,
    justifyContent: "space-between",
  },
  productBody: {
    flexDirection: "row",
    height: 44,
  },
  productImage: {
    width: 100,
    height: 80,
    marginRight: 25,
    borderRadius: 8,
    overflow: "hidden",
  },
  codeWebDesign: {
    fontWeight: FONTWEIGHT_600,
    fontSize: FONTSIZE_14,
    display: Platform.OS === "web" && "block",
    color: Colors.BLACK,
  },
  dropsubtitle: {
    fontWeight: FONTWEIGHT_600,
  },
  horizontalline: {
    marginVertical: 32,
    borderBottomWidth: 1,
    borderBottomColor: COLOR.LIGHTEN_GRAY,
    width: "100%",
  },
  tophorizontalline: {
    marginTop: 15,
    borderBottomWidth: 1,
    borderBottomColor: COLOR.LIGHTEN_GRAY,
    width: "100%",
  },
  produdtDetailsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  productDetails: {
    fontSize: 16,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.GRYSIX,
    lineHeight: 24,
    marginBottom: 8,
  },
  productAddress: {
    fontSize: 14,
    fontWeight: FONTWEIGHT_400,
    color: COLOR.BLACK,
    lineHeight: 24,
  },
  weekDays: {
    fontSize: 14,
    fontWeight: FONTWEIGHT_400,
    color: COLOR.BLACK,
    lineHeight: 24,
  },
  mainTiming: {
    fontSize: 14,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.BLACK,
    lineHeight: 24,
    marginBottom: 16,
  },
  timing: {
    fontSize: 14,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.BLACK,
    lineHeight: 24,
    marginBottom: 16,
  },
  DropMaincard: {
    backgroundColor: COLOR.LIGHT_YELLOW,
    borderRadius: 12,
    shadowColor: "transparent",
    marginVertical: 4,
  },
  DropcardContainer: {
    marginTop: 20,
  },

  // -----AddItemProductType-
  closeicon: {
    position: "absolute",
    right: "4%",
    top: "2%",
    backgroundColor: "rgba(0, 0, 0, 0.16)",
    borderRadius: 8,
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  photogallery: {
    position: "absolute",
    bottom: 0,
    flexDirection: "row",
    width: "100%",
    padding: 20,
  },
  photostyle: {
    width: 48,
    height: 48,
    borderColor: COLOR.WHITE,
    borderWidth: 3,
    borderRadius: 8,
  },
  photoupload: {
    position: "absolute",
    bottom: 0,
    flexDirection: "row",
    flex: 1,
    right: 0,
    padding: 20,
  },
  takepicture: {
    width: 72,
    height: 72,
    bottom: 0,
    borderRadius: 50,
    backgroundColor: COLOR.WHITE,
    justifyContent: "center",
    alignItems: "center",
  },
  takepicturepart: {
    alignSelf: "center",
    alignItems: "center",
    right: 0,
    width: "calc(100% - 96px)",
  },
  imagemainblock: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  croupimg: {
    position: "absolute",
    bottom: 0,
    flexDirection: "row",
    flex: 1,
    width: "100%",
  },
  croupuploadicon: {
    position: "absolute",
    right: "4%",
    bottom: 12,
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 11,
    paddingRight: 11,
  },
  flash: {
    position: "absolute",
    left: "4%",
    top: "2%",
    backgroundColor: "rgba(0, 0, 0, 0.16)",
    borderRadius: 8,
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  trademarkstyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  textStyle: {
    paddingTop: 24,
    fontSize: FONTSIZE_14,
    fontWeight: FONTWEIGHT_600,
  },
  MaterialIcons: {
    overflow: "hidden",
    borderRadius: 8,
    backgroundColor: COLOR.LIGHT_TEXT_GRAY,
    padding: 10.5,
  },
  MaterialIconsEdit: {
    borderRadius: 8,
    backgroundColor: COLOR.WHITE,
    padding: 10.5,
    shadowColor: "#000",
    shadowOffset: { width: 4, height: 8 },
    shadowRadius: 12,
    shadowOpacity: 0.4,
    elevation: 24,
  },
  slider: {
    paddingHorizontal: 20,
  },
  thumbStyle: {
    borderColor: COLOR.WHITE,
    borderWidth: 8,
    width: 24,
    height: 24,
    margin: 2,
    shadowColor: COLOR.BLACK,
    shadowOffset: { width: 1, height: Platform.OS === "web" ? 2 : 6 },
    shadowRadius: Platform.OS === "web" ? 8 : 16,
    shadowOpacity: 0.4,
    elevation: 5,
  },
  pricestyle: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginTop: 4,
  },
  imagebtn: {
    overflow: "hidden",
    color: COLOR.BLACK,
    textAlign: "center",
    fontWeight: FONTWEIGHT_600,
    lineHeight: 24,
    fontSize: 12,
    paddingHorizontal: 11,
    paddingVertical: 4,
    backgroundColor: COLOR.WHITE,
    borderRadius: 8,
  },
  btntext: {
    color: COLOR.WHITE,
    textAlign: "center",
    lineHeight: 34,
    fontWeight: FONTWEIGHT_600,
  },

  //  ----itemSoid---
  itemsoidcontainer: {
    paddingTop: 161,
    paddingHorizontal: 20,
  },
  textBody: {
    maxWidth: 326,
  },
  itemsubTitle: {
    fontSize: 14,
    marginTop: 12,
    color: COLOR.DARK_GRAY,
    lineHeight: 20,
    textAlign: "center",
    maxWidth: 266,
  },
  itemMaincard: {
    backgroundColor: COLOR.LIGHT_YELLOW,
    borderRadius: 12,
    shadowColor: "transparent",
    marginVertical: 4,
  },
  itemproductImage: {
    width: 172,
    height: 172,
    position: "relative",
    alignSelf: "center",
  },
  celebrateImage: {
    width: 48,
    height: 32,
    resizeMode: "contain",
    position: "absolute",
    right: 0,
  },
  imgtext: {
    position: "absolute",
    bottom: -11,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  itemsoid: {
    backgroundColor: COLOR.WHITE,
    overflow: "hidden",
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
    fontSize: 12,
    lineHeight: 20,
    elevation: 24,
    shadowColor: COLOR.BLACK,
    shadowOffset: { width: 4, height: 8 },
    shadowRadius: 12,
    shadowRadius: 16.0,
    shadowOpacity: 0.4,
  },

  // DropOffItemLocation.js
  mapLocation: {
    marginTop: 16,
    marginBottom: 32,
    borderColor: "lightgray",
    alignSelf: "center",
    borderRadius: 16,
    elevation: 24,
    overflow: "hidden",
    shadowColor: "transparent",
  },
  maptitle: {
    fontSize: FONTSIZE_12,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.GRYSIX,
  },
  maphorizontalline: {
    marginVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: COLOR.LIGHTEN_GRAY,
    width: "100%",
  },

  // ------Collection.js------
  collectionContainer: {
    flexDirection: "row",
    marginHorizontal: 16,
    justifyContent: "space-around",
  },
  searchIcon: {
    marginTop: 0,
    marginRight: 20,
  },
  bagIcon: {
    width: 17,
    height: 22,
    marginTop: 0,
  },
  collectionTitle: {
    fontWeight: FONTWEIGHT_400,
    fontSize: 30,
    lineHeight: 39,
    color: Colors.FONT_BLACK,
    marginBottom: 19,
    marginTop: 23,
  },
  bagTotalItem: {
    position: "relative",
    width: 17,
    height: 17,
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 17,
    alignItems: "center",
    justifyContent: "center",
    bottom: 10,
    left: 5,
  },
  bagTotalItemNumber: {
    fontWeight: FONTWEIGHT_400,
    fontSize: FONTSIZE_10,
    color: Colors.WHITE,
    marginLeft: 5,
  },
  collectionproduct: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  filterTitle: {
    fontSize: 14,
    lineHeight: 21,
    color: Colors.FONT_BLACK,
    fontWeight: FONTWEIGHT_400,
  },
  collectionInputFiled: {
    height: 60,
    backgroundColor: COLOR.WHITE,
    borderTopEndRadius: 0,
    borderTopStartRadius: 0,
    overflow: "hidden",
    borderWidth: 1,
    borderStyle: "solid",
    paddingHorizontal: 24,
    borderColor: "#7D7D7D",
  },
  menuText: {
    fontSize: 18,
    lineHeight: 21,
    color: Colors.FONT_BLACK,
    fontWeight: FONTWEIGHT_400,
    marginBottom: 15,
  },
  subMenuText: {
    fontSize: FONTSIZE_14,
    lineHeight: 21,
    color: Colors.FONT_BLACK,
    fontWeight: FONTWEIGHT_400,
    marginTop: 15,
  },
  pricelist: {
    fontWeight: FONTWEIGHT_400,
    color: Colors.FONT_BLACK,
    lineHeight: 27,
  },
  collectionProduct: {
    marginBottom: 24,
  },
  menuContainer: {
    marginHorizontal: 12.5,
    marginTop: 40,
  },
  emailContainer: {
    marginHorizontal: 22,
    marginBottom: 12,
  },
  arrowrightIcon: {
    position: "absolute",
    right: 15,
    top: 17,
  },
  socialIconContainer: {
    marginTop: 12,
    flexDirection: "row",
    alignSelf: "center",
    width: 196,
    marginBottom: 17,
  },
  poweredBySection: {
    flexDirection: "row",
    marginBottom: 24,
    alignSelf: "center",
  },
  paymentMethodContainer: {
    flexDirection: "row",
    marginBottom: 16,
  },
  copyRightSection: {
    marginBottom: 24,
    alignSelf: "center",
    textAlign: "center",
  },
  copyRightText: {
    fontSize: FONTSIZE_10,
    fontWeight: FONTWEIGHT_400,
    lineHeight: 13,
    color: Colors.FONT_BLACK,
  },
  searchbaropen: {
    marginLeft: 12,
    marginRight: 12,
  },
  closebtn: {
    position: "absolute",
    top: 0,
    bottom: 8,
    right: 35,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  inputSearchIcon: {
    position: "absolute",
    left: 0,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  inputClosedSearchIcon: {
    position: "absolute",
    top: 0,
    bottom: 7,
    right: 0,
    alignItems: "flex-end",
    justifyContent: "center",
  },

  // addAddress.s
  iconContainer: {
    marginTop: 33,
    marginBottom: 57,
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 44,
  },
  checkmarkCircle: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  darkButton: {
    height: 56,
    borderRadius: 12,
    paddingVertical: 12,
    backgroundColor: COLOR.BLACK,
  },
  darkDisableButton: {
    height: 56,
    borderRadius: 12,
    paddingVertical: 12,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  addAddressInput: {
    height: 60,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(15, 15, 15, 0.12)",
    backgroundColor: "#fff",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    overflow: "hidden",
  },
  bagFormContainer: {
    marginTop: 123,
    marginHorizontal: 20,
  },
  firstNameInput: {
    marginRight: 16,
  },
  cityInputContainer: {
    marginTop: 16,
  },
  cityInput: {
    marginRight: 16,
  },
  postcodeInput: {
    marginBottom: 4,
  },
  bookedBag: {
    height: 172,
    width: 172,
    backgroundColor: "#252525",
    borderRadius: 172,
    justifyContent: "center",
    marginTop: 57,
  },

  // ClothBagCollection.js
  bagCollectionContainer: {
    marginTop: 36,
    marginHorizontal: 44,
  },
  bagItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  clothItemText: {
    fontSize: FONTSIZE_24,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.BLACK,
  },
  clothItemImage: {
    height: 48,
    width: 48,
    backgroundColor: COLOR.LIGHT_TEXT_GRAY,
    borderRadius: 24,
    overflow: "hidden",
    justifyContent: "center",
    marginLeft: 5,
    marginRight: 10,
  },
  clothImageStyle: {
    width: 16,
    height: 16,
    resizeMode: "contain",
    alignSelf: "center",
  },
  productDetailSection: {
    flexDirection: "row",
    width: 200,
  },
  consideredByStore: {
    height: 28,
    borderRadius: 32,
    overflow: "hidden",
    backgroundColor: COLOR.LIGHT_TEXT_GRAY,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 8,
    marginBottom: 4,
    paddingHorizontal: 12,
  },
  storeText: {
    fontSize: FONTSIZE_10,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.BLACK,
  },
  headerText: {
    width: "100%",
    maxWidth: 200,
  },
  addOtherItemSection: {
    marginTop: 4,
    flexDirection: "row",
  },
  otherItemIcon: {
    width: 80,
    height: 80,
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "rgba(15, 15, 15, 0.04)",
    justifyContent: "center",
    alignItems: "center",
  },
  addOtherProduct: {
    flexDirection: "column",
    marginLeft: 24,
  },
  stepLeftText: {
    fontSize: FONTSIZE_10,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.GRAY,
  },
  footerMenuSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginHorizontal: 32,
    marginBottom: 16,
  },
  addItemIcon: {
    width: 48,
    height: 48,
    borderRadius: 24,
    overflow: "hidden",
    backgroundColor: Colors.BLACK,
    alignItems: "center",
    justifyContent: "center",
  },
  addItemIconProfile: {
    width: 160,
    height: 48,
    borderRadius: 24,
    overflow: "hidden",
    backgroundColor: Colors.BLACK,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 15,
  },
  addHeaderButton: {
    borderRadius: 12,
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 32,
    paddingVertical: 15,
  },

  // ItemDetails.js
  itemDetailsContainer: {
    marginTop: 36,
    marginHorizontal: 20,
  },
  closeMenuSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 28,
  },
  dotMenuIcon: {
    backgroundColor: "rgba(0, 0, 0, 0.16)",
    borderRadius: 8,
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonNone: {
    display: "none",
  },
  itemDetail: {
    marginTop: 28,
    marginHorizontal: 24,
    marginBottom: 77,
    flexDirection: "row",
  },
  priceTagContainer: {
    width: 115,
    backgroundColor: COLOR.WHITE,
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
    shadowColor: COLOR.BLACK,
    shadowOffset: { width: 2, height: 4 },
    shadowRadius: 12,
    shadowOpacity: 0.4,
    elevation: 24,
  },
  priceTagBody: {
    overflow: "hidden",
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 56,
  },
  bagProductContainer: {
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemDetailHR: {
    marginTop: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLOR.LIGHTEN_GRAY,
    width: "100%",
  },
  messageAlertSection: {
    flexDirection: "row",
    marginBottom: 35,
    alignItems: "center",
    marginVertical: 34,
    marginRight: 42,
  },
  messageAlert: {
    marginRight: 42,
    marginLeft: 22,
  },
  alertText: {
    fontSize: FONTSIZE_12,
    fontWeight: FONTWEIGHT_400,
    color: COLOR.TEXT_GRAY,
  },

  // DropOffBagItem.js
  dropOffItemMap: {
    width: 390,
    height: 400,
  },
  dropOffMapSection: {
    borderRadius: 0,
    position: "relative",
  },
  dropItemAddressSection: {
    marginHorizontal: 20,
    marginTop: 24,
  },
  dropOffInfoText: {
    fontSize: 14,
    fontWeight: FONTWEIGHT_500,
    color: COLOR.BLACK,
    lineHeight: 24,
    textAlign: "center",
  },
  bagItem: {
    justifyContent: "center",
    alignItems: "center",
  },
  footerButton: {
    marginHorizontal: 20,
    marginBottom: 21,
  },
  bagItemButton: {
    paddingHorizontal: 100,
    backgroundColor: COLOR.LIGHT_TEXT_GRAY,
  },
  closeIconPosition: {
    position: "absolute",
    zIndex: 1,
    right: 20,
    top: 20,
  },
  callouttolltip: {
    color: "#000",
    backgroundColor: "#ffffff",
    width: "100%",
    paddingHorizontal: 12,
    paddingVertical: 4,
    shadowColor: "#000",
    fontWeight: FONTWEIGHT_600,
    fontSize: 12,
    lineHeight: 24,
    shadowOffset: { width: 8, height: 8 },
  },

  // SoldClothBagCollection.js
  soldItemSection: {
    marginHorizontal: 32,
  },
  collectionTitle: {
    fontSize: FONTSIZE_24,
    fontWeight: FONTWEIGHT_300,
    color: COLOR.WHITE,
    lineHeight: 24,
  },
  numberOfSoldData: {
    marginVertical: 16,
  },
  numberOfSold: {
    fontSize: FONTSIZE_12,
    fontWeight: FONTWEIGHT_600,
    color: COLOR.TEXT_GRAY,
    lineHeight: 20,
  },
  numberOfSoldTotal: {
    fontSize: 20,
    fontWeight: FONTWEIGHT_600,
    color: Colors.BLACK,
    lineHeight: 32,
  },
  availableProduct: {
    marginHorizontal: 20,
  },
  coreBagHeader: {
    marginVertical: 14,
    marginHorizontal: 20,
    borderRadius: 20,
    overflow: "hidden",
  },
  linearGradientStyle: {
    position: "relative",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: 10,
  },
  darkThemeCardContainer: {
    marginHorizontal: 20,
    marginBottom: 16,
  },

  // SwiperAccept.js
  dotStyle: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    width: 6,
    height: 6,
    borderRadius: 4,
    margin: 3,
    overflow: "hidden",
  },
  activeDotStyle: {
    backgroundColor: Colors.WHITE,
    width: 6,
    height: 6,
    borderRadius: 4,
    margin: 3,
    overflow: "hidden",
  },
  darkThemeCard: {
    backgroundColor: "#252525",
    marginHorizontal: 5,
  },

  // SwiperRetailItem.js
  retailDotStyle: {
    backgroundColor: "rgba(255, 255, 255, 0.48)",
    width: 6,
    height: 6,
    borderRadius: 4,
    margin: 3,
    overflow: "hidden",
  },
  swipeImage: {
    width: null,
    height: 545,
    flex: 1,
    resizeMode: "cover",
  },

  // QRcodeScanner.js
  flexCenter: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  camDisplay: {
    zIndex: 1,
    width: 900,
    height: 900,
  },

  //CoreBagCollectionFooter.js
  modalColseButton: {
    textAlign: "right",
    position: "absolute",
    top: 5,
    left: 5,
    zIndex: 1,
  },

  dropdown: {
    height: 25,
    cursor: "pointer",
  },

  placeholderStyle: {
    fontSize: 12,
  },
  selectedTextStyle: {
    fontSize: 12,
  },
  iconStyle: {
    display: "none",
  },
  chatDayView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
  },
  dayStyle: {
    fontSize: 12,
    backgroundColor: "#f0f0f0",
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 10,
  }
});
