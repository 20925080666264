import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Platform,
  StyleSheet,
  View,
  SafeAreaView,
  StatusBar,
  ScrollView,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Entypo } from "@expo/vector-icons";
import styles from "../components/style/styles.js";
import RNPickerSelect from "react-native-picker-select";
import Button from "../components/Button";

const subDomain =
  Platform.OS === "web"
    ? window.location.hostname === "localhost"
      ? ""
      : window.location.host.replace("www.", "").split(".")[0]
    : "";

const SelectOrganisation = ({ navigation, route }) => {
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const queryParams = new URLSearchParams(window.location.search);

  useEffect(async () => {
    if (route?.name === "SelectOrganisation") {
      if (subDomain) {
        navigation.navigate("HomePage", {
          organisationId: subDomain,
        });
      }
    }
    if (route?.params?.sort) {
      if (performance.navigation.type === 1) {
        const getUser = await AsyncStorage.getItem("customer");
        const _user = JSON.parse(getUser);
        const _organisation = await AsyncStorage.getItem("organisation");
        if (_user?.isLogin) {
          if (orgDetail?.[0]?.cashierIntegration == "boxnet") {
            navigation.push("SoldClothBagCollectionIntegration", {
              organisationId: _organisation,
            });
          } else {
            navigation.push("SoldClothBagCollection", {
              organisationId: _organisation,
            });
          }
        } else {
          if (_organisation !== null) {
            if (route?.params?.sort === "DropOffItem") {
              navigation.navigate("DropOffItem", {
                user: route?.params?.user,
                item: route?.params?.item,
                organisationId: subDomain,
              });
            } else {
              navigation.navigate("HomePage", {
                organisationId: _organisation,
              });
            }
          }
        }
      } else {
        if (route?.params?.sort === "HomePage") {
          navigation.push("SelectOrganisation");
        } else if (route?.params?.sort === "DropOffItem") {
          if (route?.params?.cartImage) {
            navigation.navigate("DropOffItem", {
              cartImage: route?.params?.cartImage,
              textValue: route?.params?.textValue,
            });
          } else {
            navigation.navigate("DropOffItem", {
              user: route?.params?.user,
              item: route?.params?.item,
              organisationId: subDomain,
            });
          }
        } else {
          if (route?.params?.sort === "undefined") {
            navigation.push("SelectOrganisation");
          } else if (route?.params?.sort === "TermsCondition") {
            const organisationId = queryParams.get("organisationId");
            if (subDomain) {
              navigation.navigate("TermsCondition", {
                organisationId: subDomain,
              });
            } else {
              navigation.navigate("TermsCondition", {
                organisationId: organisationId || route?.params?.organisationId,
              });
            }
          } else {
            if (subDomain) {
              navigation.navigate("HomePage", {
                organisationId: subDomain,
              });
            } else {
              navigation.navigate("HomePage", {
                organisationId: route?.params?.sort,
              });
            }
          }
        }
      }
      if (route?.params?.sort === "register") {
        const email = queryParams.get("user");
        const hashCode = queryParams.get("hashCode");
        const organisationId = queryParams.get("organisationId");
        if (subDomain) {
          navigation.navigate("register", {
            user: email,
            hashCode: hashCode,
            organisationId: subDomain,
          });
        } else {
          navigation.navigate("register", {
            user: email,
            hashCode: hashCode,
            organisationId: organisationId || route?.params?.organisationId,
          });
        }
      }
      if (route?.params?.sort === "zimpler_status") {
        const status = queryParams.get("status");
        const organisationId = queryParams.get("organisationId");
        if (subDomain) {
          navigation.navigate("zimpler_status", {
            status: status,
            organisationId: subDomain,
          });
        } else {
          navigation.navigate("zimpler_status", {
            status: status,
            organisationId: organisationId || route?.params?.organisationId,
          });
        }
      }
    }
  }, []);

  const [organisationId, setOrganisationId] = useState("");
  const [userList, setUserList] = useState([]);

  const handleNext = () => {
    navigation.navigate("HomePage", { organisationId });
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 200,
            marginHorizontal: 22,
          }}
        >
          {Platform.OS === "web" ? (
            <RNPickerSelect
              placeholder={{
                label: "Select organisation",
              }}
              items={userList}
              onValueChange={(value) => {
                setOrganisationId(value);
              }}
              useNativeAndroidPickerStyle={false}
              ellipsizeMode="tail"
              value={organisationId}
              textInputProps={{ selection: { start: 0 } }}
            />
          ) : (
            <RNPickerSelect
              placeholder={{
                label: "Select organisation",
              }}
              items={userList}
              onValueChange={(value) => {
                setOrganisationId(value);
              }}
              useNativeAndroidPickerStyle={false}
              style={{
                ...pickerSelectStyles,
                iconContainer: {
                  top: Platform.OS === "android" ? 12 : 10,
                  right: 5,
                },
                placeholder: {
                  color: "#000",
                },
              }}
              ellipsizeMode="tail"
              // value={organisationId}
              textInputProps={{ selection: { start: 0 } }}
              Icon={() => {
                return (
                  <Entypo name="chevron-small-down" size={24} color="black" />
                );
              }}
            />
          )}
        </View>
      </ScrollView>
      <View style={{ marginHorizontal: 22, marginBottom: 20 }}>
        <Button
          btnTitle="Fortsätt"
          // btnTitle="Nej"
          style={[styles.signUpButton]}
          lineHeight={30}
          onPress={() => handleNext()}
          issinup={organisationId}
        />
      </View>
    </SafeAreaView>
  );
};

export default SelectOrganisation;

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 13,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: "gray",
    color: "black",
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 13,
    paddingHorizontal: 8,
    borderWidth: 0.5,
    borderColor: "#000",
    color: "black",
    paddingRight: 25,
    width: 320,
    height: 50,
    borderRadius: 8,
  },
});
