import AsyncStorage from "@react-native-async-storage/async-storage";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import UserPool from "../aws-exports";

const userPool = UserPool;

export const signOut = ({ poolData }) => {
  poolData?.getCurrentUser()?.signOut();
  AsyncStorage.removeItem("token");
  AsyncStorage.removeItem("user");
  AsyncStorage.removeItem("customer");
};
