import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  Image,
  Linking,
} from "react-native";
import { useSelector } from "react-redux";
import styles from "../components/style/styles";
import {
  addTransactionData,
  addCustomerAccountDetail,
  myDetails,
  adjustComissionData,
} from "../actions";
import CoreText from "../components/core/CoreText";
import { TextInput } from "react-native-paper";
import Button from "../components/Button";
import { ScrollView } from "react-native-gesture-handler";
import CoreFooter from "../components/core/CoreFooter";
import CoreBagCollectionHeader from "../components/core/CoreBagCollectionHeader";
import CoreLoader from "../components/core/CoreLoader";
import Header from "../components/core/Header";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");

const CommissionWithdraw = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const totalItem = route.params.totalItem;
  const allComission = route.params.allComission;
  const orgLaunguage = useSelector((state) => state.prodReducer.languageData);
  const [loadItem, setLoadItem] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [accountErrorMsg, setAccountErrorMsg] = useState("");
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [isLoadCustomer, setIsLoadCustomer] = useState(false);
  const [userData, setuserData] = useState("");
  const [amount, setAmount] = useState("");
  const [accountNumber, setAccountNumber] = useState();
  const [zimplerDisplay, setZimplerDisplay] = useState(false);
  const [manualDisplay, setManualDisplay] = useState(false);
  const [isGiftCard, setIsGiftCard] = useState(false);
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const organisationId = orgDetail[0]?.urlPath;
  const regex = /^[-+]?[0-9]+$/;
  const reg = /^[A-Za-z0-9 ]+$/;

  const disposableBalance =
    userData?.balance - userData?.unprocessedPayoutRequests || 0;

  const withdrowAllMethod =
    (orgSetting?.payoutMethod?.includes("zimpler") &&
      orgSetting?.payoutMethod?.includes("manual") &&
      orgSetting?.payoutMethod?.includes("giftCard")) ||
    orgSetting?.payoutMethod?.includes("zimpler") ||
    orgSetting?.payoutMethod?.includes("manual");

  const zimplerMethod =
    (zimplerDisplay &&
      orgSetting?.isZimplerPublic &&
      orgSetting?.payoutMethod?.includes("zimpler")) ||
    (zimplerDisplay &&
      userData?.isZimplerEnable &&
      orgSetting?.isZimplerEnable &&
      orgSetting?.payoutMethod?.includes("zimpler"));

  const manualMethod =
    (manualDisplay && orgSetting?.payoutMethod?.includes("manual")) ||
    (isGiftCard && orgSetting?.payoutMethod?.includes("giftCard"));

  useEffect(async () => {
    await myDetails(organisationId)
      .then((resp) => {
        setuserData(resp?.data[0]);
        setLoadItem(false);
        setAccountNumber(resp?.data[0]?.accountDetail?.accountNo);
      })
      .catch((error) => { });
  }, []);

  const handleNextZimpler = async () => {
    setIsLoadCustomer(true);
    if (!reg.test(accountNumber)) {
      setAccountErrorMsg("inte lägga till specialtecken");
      setIsLoadCustomer(false);
    } else {
      const data = {
        organisationId: organisationId,
        email: userData?.email,
        accountDetail: {
          accountNo: accountNumber,
        },
      };
      setIsLoadCustomer(true);
      addCustomerAccountDetail(data);

      if (!regex.test(amount)) {
        setErrorMsg("Ange ett belopp i siffror");
        setIsLoadCustomer(false);
      } else if (
        (!orgSetting?.isZimplerEnable ||
          (orgSetting?.isZimplerEnable && !userData?.isZimplerEnable)) &&
        amount > disposableBalance
      ) {
        setErrorMsg(
          `Beloppet kan inte vara högre än ditt tillgängliga saldo ${disposableBalance?.toFixed(
            2
          )}`
        );
        setIsLoadCustomer(false);
      } else if (
        (orgSetting?.isZimplerPublic ||
          (userData?.isZimplerEnable && orgSetting?.isZimplerEnable)) &&
        amount < 100
      ) {
        setErrorMsg("vänligen ange ditt belopp mer än 100");
        setIsLoadCustomer(false);
      } else if (
        orgSetting?.isZimplerPublic ||
        (userData?.isZimplerEnable && orgSetting?.isZimplerEnable)
      ) {
        adjustComissionData(parseInt(amount), organisationId).then((resp) => {
          if (resp?.data?.Success) {
            // Linking.openURL(resp?.data?.url);
            window.location.href = resp?.data?.url;
            // setTransactionSuccess(true);
          } else {
            setErrorMsg(resp?.data?.message);
          }

          setIsLoadCustomer(false);
        });
      }
    }
  };

  const handleNextManual = async () => {
    setIsLoadCustomer(true);
    if (!reg.test(accountNumber)) {
      setAccountErrorMsg("inte lägga till specialtecken");
      setIsLoadCustomer(false);
    } else {
      const data = {
        organisationId: organisationId,
        email: userData?.email,
        accountDetail: {
          accountNo: accountNumber,
        },
      };
      setIsLoadCustomer(true);
      addCustomerAccountDetail(data);

      if (!regex.test(amount)) {
        setErrorMsg("Ange ett belopp i siffror");
        setIsLoadCustomer(false);
      } else {
        const data = {
          primaryKey: organisationId,
          organisation_id: organisationId,
          customer_id: userData?.id,
          amount: parseInt(amount),
          type: "withdrawal requested",
          description: "",
        };
        setIsLoadCustomer(true);
        addTransactionData(data, isGiftCard && "GiftCard").then((resp) => {
          setTransactionSuccess(true);
        });
      }
    }
  };
  const accountNumberData = (
    <>
      <View style={styles.availableProduct}>
        <View style={{ marginTop: 10 }}>
          <CoreText
            style={styles.numberOfSold}
            textTitle="Ange ditt kontonummer"
            fontType={MontserratBold}
          />
        </View>
        <TextInput
          label={
            <CoreText
              textTitle="Ange ditt kontonummer"
              style={[styles.input]}
              fontType={MontserratRegular}
              isInput
            />
          }
          style={[styles.inputFiledstyle]}
          value={accountNumber}
          keyboardType="numeric"
          placeholderTextColor="#878787"
          underlineColor="transparent"
          activeUnderlineColor="transparent"
          onChangeText={(e) => {
            setAccountNumber(e);
            setAccountErrorMsg("");
          }}
        />
      </View>
      {accountErrorMsg && (
        <View style={{ marginHorizontal: 20 }}>
          <CoreText
            textTitle={accountErrorMsg}
            style={[styles.error]}
            fontType={MontserratRegular}
          />
        </View>
      )}
    </>
  );

  const accountPayment = (
    <>
      <View style={styles.availableProduct}>
        <View style={{ marginTop: 20 }}>
          <CoreText
            style={styles.numberOfSold}
            textTitle="Skriv in beloppet du vill ta ut"
            fontType={MontserratBold}
          />
        </View>
        <TextInput
          label={
            <CoreText
              textTitle="Välj belopp att ta ut"
              style={[styles.input]}
              fontType={MontserratRegular}
              isInput
            />
          }
          style={[styles.inputFiledstyle]}
          value={amount}
          keyboardType="numeric"
          placeholderTextColor="#878787"
          underlineColor="transparent"
          activeUnderlineColor="transparent"
          onChangeText={(e) => {
            setAmount(e);
            setErrorMsg("");
          }}
        />
      </View>
      {errorMsg && (
        <View style={{ marginHorizontal: 20 }}>
          <CoreText
            textTitle={errorMsg}
            style={[styles.error]}
            fontType={MontserratRegular}
          />
        </View>
      )}
      {orgDetail?.[0]?.costOfTransaction > 0 ? (
        <CoreText
          style={{
            padding: 15,
            color:
              orgDetail?.[0]?.primaryColor ||
              orgDetail[0]?.secondaryColor ||
              Colors.TEXT_GRAY,
            fontStyle: "italic",
          }}
          textTitle={`Vi tar en uttagsavgift på ${orgDetail?.[0]?.costOfTransaction
            } ${orgSetting?.currency ? orgSetting?.currency : "$"}`}
          fontType={MontserratBold}
        />
      ) : (
        ""
      )}
    </>
  );

  const handleSubmitZimpler = (
    <View style={[styles.btnsection, { marginTop: 30 }]}>
      <Button
        isLoad={isLoadCustomer}
        btnTitle={orgLaunguage?.commisionWithdraw_button || "Ta ut pengar"}
        disableButtondata={
          accountErrorMsg ||
          errorMsg ||
          !amount ||
          (!orgSetting?.isZimplerEnable &&
            !userData?.isZimplerEnable &&
            !orgSetting?.isZimplerPublic &&
            !accountNumber)
        }
        disabled={
          accountErrorMsg ||
          errorMsg ||
          !amount ||
          (!orgSetting?.isZimplerEnable &&
            !userData?.isZimplerEnable &&
            !orgSetting?.isZimplerPublic &&
            !accountNumber)
        }
        textstyle={[
          !accountErrorMsg || !errorMsg
            ? styles.btntextstyle
            : styles.disableBtnStyle,
          { lineHeight: 36, marginTop: 0 },
        ]}
        onPress={() => handleNextZimpler()}
      />
    </View>
  );

  const handleSubmitManual = (
    <View style={[styles.btnsection, { marginTop: 30 }]}>
      <Button
        isLoad={isLoadCustomer}
        btnTitle={orgLaunguage?.commisionWithdraw_button || "Ta ut pengar"}
        disableButtondata={
          accountErrorMsg || errorMsg || !amount || !accountNumber
        }
        disabled={accountErrorMsg || errorMsg || !amount || !accountNumber}
        textstyle={[
          !accountErrorMsg || !errorMsg
            ? styles.btntextstyle
            : styles.disableBtnStyle,
          { lineHeight: 36, marginTop: 0 },
        ]}
        onPress={() => handleNextManual()}
      />
    </View>
  );

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      {loadItem ? (
        <CoreLoader
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        <>
          <Header navigation={navigation} route={route} isHomePage isHeader />
          {!transactionSuccess ? (
            <ScrollView>
              <View>
                <View style={styles.coreBagHeader}>
                  <CoreBagCollectionHeader
                    totalItem={totalItem}
                    comission={allComission}
                    isHeaderImage
                  />
                </View>
              </View>
              <View style={{ marginHorizontal: 20, marginVertical: 10 }}>
                <View style={{ marginTop: 10 }}>
                  <CoreText
                    style={styles.numberOfSold}
                    textTitle="Här kan du begära ut dina pengar. När vi har mottagit din förfrågan så kommer vi godkänna det och därefter betala ut i enlighet med avtalet."
                    fontType={MontserratBold}
                  />
                </View>
                {userData?.unprocessedPayoutRequests > 0 && (
                  <View style={{ marginTop: 10 }}>
                    <CoreText
                      style={styles.numberOfSold}
                      textTitle={
                        <>
                          Du har begärt ett uttag på{""}
                          <span style={{ fontWeight: 600, color: "#000" }}>
                            {" "}
                            {userData?.unprocessedPayoutRequests}
                          </span>{" "}
                          som fortfarande bearbetas. Din tillgängliga balans är
                          <span style={{ fontWeight: 600, color: "#000" }}>
                            {" "}
                            {disposableBalance?.toFixed(2)}.
                          </span>
                        </>
                      }
                      fontType={MontserratBold}
                    />
                  </View>
                )}
              </View>
              <View>
                <>
                  {!zimplerDisplay &&
                    !manualDisplay &&
                    !isGiftCard &&
                    orgSetting?.payoutMethod?.includes("zimpler") && (
                      <View style={[styles.btnsection, { marginTop: 15 }]}>
                        <Button
                          isLoad={isLoadCustomer}
                          btnTitle={"Direkt utbetalning till bank"}
                          textstyle={[
                            styles.btntextstyle,
                            { lineHeight: 36, marginTop: 0 },
                          ]}
                          onPress={() => {
                            setZimplerDisplay(true);
                            setManualDisplay(false);
                            setIsGiftCard(false);
                          }}
                        />
                      </View>
                    )}
                  {!manualDisplay &&
                    !zimplerDisplay &&
                    !isGiftCard &&
                    orgSetting?.payoutMethod?.includes("manual") && (
                      <View style={[styles.btnsection, { marginTop: 15 }]}>
                        <Button
                          isLoad={isLoadCustomer}
                          btnTitle={"Överföring till bank"}
                          textstyle={[
                            styles.btntextstyle,
                            { lineHeight: 36, marginTop: 0 },
                          ]}
                          onPress={() => {
                            setManualDisplay(true);
                            setZimplerDisplay(false);
                            setIsGiftCard(false);
                          }}
                        />
                      </View>
                    )}

                  {!manualDisplay &&
                    !zimplerDisplay &&
                    !isGiftCard &&
                    orgSetting?.payoutMethod?.includes("giftCard") && (
                      <View style={[styles.btnsection, { marginTop: 15 }]}>
                        <Button
                          isLoad={isLoadCustomer}
                          btnTitle={"Gift Card"}
                          textstyle={[
                            styles.btntextstyle,
                            { lineHeight: 36, marginTop: 0 },
                          ]}
                          onPress={() => {
                            setIsGiftCard(true);
                            setManualDisplay(false);
                            setZimplerDisplay(false);
                          }}
                        />
                      </View>
                    )}
                </>
              </View>
              <View>
                {withdrowAllMethod ? (
                  <>
                    {zimplerMethod ? (
                      <>
                        {accountPayment}
                        {handleSubmitZimpler}
                      </>
                    ) : manualMethod ? (
                      <>
                        {accountNumberData}
                        {accountPayment}
                        {handleSubmitManual}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    {accountNumberData}
                    {accountPayment}
                    {handleSubmitManual}
                  </>
                )}
              </View>
            </ScrollView>
          ) : (
            <ScrollView>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: "72vh",
                }}
              >
                <View style={styles.blockdata}>
                  <View style={styles.successimageContainer}>
                    <Image
                      style={styles.imgbanner}
                      source={require("../assets/images/itemsuccess.png")}
                    />
                  </View>
                </View>
                <CoreText
                  textTitle="Tack, beloppet betalas ut så fort vi har godkänt förfrågan"
                  style={styles.titleHeader}
                  fontType={MontserratMedium}
                />
              </View>
              <View>
                <Button
                  btnTitle="Fortsätt"
                  onPress={() =>
                    navigation.navigate("SoldClothBagCollection", {
                      organisationId: pickOrg,
                    })
                  }
                  style={[
                    {
                      marginHorizontal: 20,
                    },
                  ]}
                  lineHeight={30}
                />
              </View>
            </ScrollView>
          )}
          <CoreFooter />
        </>
      )}
    </SafeAreaView>
  );
};
export default CommissionWithdraw;
