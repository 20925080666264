import React from "react";
import {
  View,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
  TouchableOpacity,
} from "react-native";
import { useSelector } from "react-redux";
import { Card } from "react-native-paper";
import styles from "../components/style/styles.js";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import Header from "../components/core/Header";
import Colors from "../constants/Colors";
import CoreFooter from "../components/core/CoreFooter";

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const Additem = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgLaunguage = useSelector((state) => state.prodReducer.languageData);
  const germentInfo = orgDetail?.[0]?.garments || [];

  const customPageList = orgDetail[0]?.customPages
    ? JSON.parse(orgDetail[0]?.customPages)
    : {};

  const handleNext = () => {
    let pageUrl = "AddItemCart"
    if (orgDetail[0]?.urlPath === 'sandqvist') {
      // if (customPageList?.isHideItemCart && customPageList?.isHideOption && customPageList?.isHideSearch && customPageList?.isHidePrice) {
      //   pageUrl = "AddItemProductType"
      // } else
      if (customPageList?.isHideItemCart && customPageList?.isHideOption && customPageList?.isHideSearch) {
        pageUrl = "AddItemProductType"
      } else if (customPageList?.isHideItemCart && customPageList?.isHideOption) {
        pageUrl = "AddItemSearch"
      } else if (customPageList?.isHideItemCart) {
        pageUrl = "Additemoption"
      } else {
        pageUrl = "AddItemCart"
      }
    }
    navigation.navigate(pageUrl, {
      organisationId: pickOrg,
    });
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <Header navigation={navigation} route={route} isHeader />
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View style={[styles.indiskatext, { marginBottom: 30 }]}>
            <View style={{ paddingHorizontal: 20 }}>
              <CoreText
                textTitle={
                  orgLaunguage?.additem_title ||
                  "Just nu letar vi speciellt efter dessa plagg:"
                }
                style={[
                  styles.mainTitle,
                  {
                    marginTop: 24,
                    color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                  },
                ]}
                fontType={MontserratMedium}
              />
            </View>
          </View>
          <View style={styles.AddcardContainer}>
            <TouchableOpacity
              // onPress={() =>
              //   navigation.navigate("AddItemCart", {
              //     organisationId: pickOrg,
              //   })
              // }
              onPress={handleNext}
            >
              {germentInfo?.map((item, id) => (
                <Card style={styles.Maincard} key={id}>
                  <Card.Content
                    style={[styles.cardContent, { paddingVertical: 16 }]}
                  >
                    <View style={[styles.cardTitle, { marginBottom: 10 }]}>
                      <CoreText
                        textTitle={item.name}
                        style={[styles.cardHeader]}
                        fontType={MontserratBold}
                        isFrom
                      />
                    </View>
                    <CoreText
                      textTitle={item.detail}
                      style={[
                        styles.CardParagraph,
                        {
                          display: "flex",
                          overflow: "visible",
                        },
                      ]}
                      fontType={MontserratMedium}
                    />
                  </Card.Content>
                </Card>
              ))}
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      <View
        style={[
          styles.btnSection,
          styles.footerButtonSection,
          { marginBottom: 30 },
        ]}
      >
        <Button
          btnTitle={orgLaunguage?.additem_button || "Lägg till ditt plagg här"}
          style={[
            {
              marginTop: 12,
            },
          ]}
          // onPress={() =>
          //   navigation.navigate("AddItemCart", {
          //     organisationId: pickOrg,
          //   })
          // }
          onPress={handleNext}
          lineHeight={29}
        />
      </View>
      <CoreFooter />
    </SafeAreaView>
  );
};
export default Additem;
