import React from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
  useWindowDimensions,
} from "react-native";
import { useSelector } from "react-redux";
import { Entypo } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import styles from "../components/style/styles.js";
import CoreImage from "../components/core/CoreImage";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import Colors from "../constants/Colors";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const Resale = ({ navigation }) => {
  const divStyle = useWindowDimensions().width;
  const orgDetail = useSelector((state) => state.prodReducer.products);

  const resaleInstruction = [
    {
      title: "50% i komission insatt på bankkonto",
    },
    {
      title: "100% i presentkort",
    },
  ];

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View style={styles.containerBody}>
            <AntDesign
              name="left"
              size={24}
              color={Colors.LIGHTEST_GRAY}
              onPress={() => navigation.goBack()}
            />
            <View style={[styles.FlexContainer, { width: 400 }]}>
              <CoreImage imgUrl={orgDetail?.[0]?.logoType} />
            </View>
          </View>
          <View style={styles.contentTitle} />
          <View style={styles.contentHeader}>
            <View style={styles.contentHeaderBody}>
              <CoreText
                textTitle="Resale Vilkor"
                style={[styles.mainTitle, { marginTop: 40, marginBottom: 31 }]}
                fontType={MontserratMedium}
              />
              <View style={{ marginTop: 32, marginBottom: 14 }}>
                <CoreText
                  textTitle="Vi kommer ha alla plagg som förljer våra skick-regler i butik."
                  style={[styles.subTitle]}
                  fontType={MontserratExtraLight}
                />
              </View>
              <View style={{ marginTop: 14 }}>
                <CoreText
                  textTitle="Vid försäljning så får du välja mellan"
                  style={[styles.subTitle, { paddingTop: 0 }]}
                  fontType={MontserratExtraLight}
                />
                <View style={{ width: 250 }}>
                  {resaleInstruction.map((item, index) => (
                    <View style={{ flexDirection: "row" }} key={index}>
                      <Entypo name="dot-single" size={24} color="black" />
                      <CoreText
                        textTitle={item.title}
                        style={[styles.subTitle, { paddingTop: 1 }]}
                        fontType={MontserratExtraLight}
                      />
                    </View>
                  ))}
                </View>
              </View>
              <View style={{ marginTop: 32 }}>
                <CoreText
                  textTitle="Plaggen kommer säljas i 45 dagar, därefter så har du 5 dagar på dig att hämta osålda plagg annars skänks dom till Myrorna."
                  style={[styles.subTitle, { paddingTop: 0 }]}
                  fontType={MontserratExtraLight}
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <Button
        style={[
          {
            marginHorizontal: 20,
            marginBottom: 21,
          },
        ]}
        onPress={() => navigation.navigate("AddItemCancle")}
        btnTitle="Fortsätt"
        lineHeight={30}
      />
    </SafeAreaView>
  );
};
export default Resale;
