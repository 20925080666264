import React, { useState } from "react";
import { Text, View, TextInput, Platform, Dimensions } from "react-native";
import styles from "../style/styles.js";
import { MaterialIcons } from "@expo/vector-icons";
import CoreText from "../core/CoreText";
import Color from "../../constants/Colors";

const { width } = Dimensions.get("window");
const MontserratBold = require("../../assets/Font/Montserrat-Bold.ttf");

const EditableData = ({
  title,
  inputvalue,
  testID,
  isSecond,
  borderColorprops,
  productsId,
  setTextValue,
  textValue,
}) => {
  const [inputChange, setInputChange] = useState(false);

  const onPress = () => {
    setInputChange(true);
    if (Platform.OS === "web") {
      if (isSecond) {
        document.querySelector("[data-testid='two_input']").focus();
      } else {
        document.querySelector("[data-testid='one_input']").focus();
      }
    }
  };

  const onFocus = () => {
    setInputChange(true);
  };

  const handleInputTextChange = (newText) => {
    setTextValue(newText);
  };

  const onBlur = () => {
    setInputChange(false);
  };

  const TextInputstyle = (() => {
    if (inputChange) {
      return [
        {
          borderColor: borderColorprops,
          padding: 5,
          marginTop: 4,
          borderRadius: 9,
          marginRight: 5,
        },
      ];
    } else {
      return [{ borderColor: "transparent" }];
    }
  })();

  return (
    <View style={styles.trademarkstyle}>
      <View style={{ width: width - 82 }}>
        <CoreText
          textTitle={title}
          style={[styles.textStyle, { paddingTop: 0 }]}
          fontType={MontserratBold}
        />
        <TextInput
          style={[
            TextInputstyle,
            {
              borderWidth: 1,
              fontSize: 14,
              fontFamily: "Montserrat",
              lineHeight: 20,
            },
          ]}
          defaultValue={inputvalue}
          onChangeText={handleInputTextChange}
          editable={inputChange}
          returnKeyType="next"
          onBlur={() => onBlur()}
          testID={testID}
          maxLength={35}
          disableFullscreenUI={inputChange}
          caretHidden={false}
        />
      </View>
      <View style={{ alignSelf: "center" }}>
        <MaterialIcons
          name="mode-edit"
          size={15}
          color="rgba(15, 15, 15, 0.72)"
          style={styles.MaterialIcons}
          onPress={() => onPress()}
        />
      </View>
    </View>
  );
};
export default EditableData;
