import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
} from "react-native";
import QRCode from "react-native-qrcode-svg";
import { useSelector, useDispatch } from "react-redux";
import {
  setProduct,
  setUserPoolData,
} from "../components/redux/actions/prodActions";
import { getOrganisationById } from "../actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import styles from "../components/style/styles.js";
import { ScrollView } from "react-native-gesture-handler";
import CoreBanner from "../components/core/CoreBanner";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import CoreLoader from "../components/core/CoreLoader";
import Header from "../components/core/Header";
import Colors from "../constants/Colors";
import { getPool } from "../components/aws-exports";
import { Provider, Portal, Modal } from "react-native-paper";
import { AntDesign } from "@expo/vector-icons";
import CoreFooter from "../components/core/CoreFooter";

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");
const HomePage = ({ navigation, route }) => {
  const organisationId = route.params.organisationId;
  const [isLoad, setIsLoad] = useState(true);
  const [organisation, setOrganisation] = useState({});
  AsyncStorage.setItem("orgName", organisation?.name || "");
  const [codeVisible, setCodeVisible] = useState(true);

  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgLaunguage = useSelector((state) => state.prodReducer.languageData);
  const switchItemData = orgDetail[0]?.theme?.setting[0]?.value === "true";
  const dispatch = useDispatch();

  useEffect(async () => {
    AsyncStorage.setItem("organisation", organisationId);

    let poolData = await getPool();
    dispatch(setUserPoolData(poolData));
    await getOrganisationById(organisationId)
      .then((resp) => {
        const org = resp?.data[0];
        setOrganisation(org);

        dispatch(setProduct(resp?.data));
        setIsLoad(false);
      })
      .catch((error) => {});
  }, []);

  let check = true;
  if (Platform.OS === "web") {
    check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
  }

  const hideCodeModal = () => setCodeVisible(false);
  return isLoad ? (
    <CoreLoader
      style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    />
  ) : (
    <Provider>
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        }}
      >
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          enabled
        >
          <Header navigation={navigation} route={route} isHomePage isHeader />
          <ScrollView>
            <Portal>
              {!check && !switchItemData && (
                <Modal
                  visible={codeVisible}
                  onDismiss={hideCodeModal}
                  contentContainerStyle={{
                    backgroundColor: "white",
                    marginVertical: 0,
                    marginHorizontal: 15,
                    borderRadius: 10,
                    alignSelf: "center",
                    maxWidth: 400,
                  }}
                >
                  <View
                    style={{
                      textAlign: "end",
                      paddingHorizontal: 10,
                      paddingTop: 10,
                    }}
                  >
                    <AntDesign
                      name="closesquareo"
                      size={24}
                      color="black"
                      onPress={() => hideCodeModal()}
                    />
                  </View>
                  <View style={{ paddingBottom: 15, paddingHorizontal: 15 }}>
                    <CoreText
                      textTitle="Att skicka in plagg till oss funkar bäst med mobilen, öppna kamera appen på din telefon, rikta kameran mot QR koden här på skärmen, klicka på länken så kan du fortsätta därifrån "
                      style={styles.codeWebDesign}
                      fontType={MontserratMedium}
                    />
                    <View style={{ padding: 15, margin: "auto" }}>
                      <QRCode
                        value={
                          window.location.protocol + "//" + window.location.host
                        }
                        logoSize={200}
                      />
                    </View>
                  </View>
                </Modal>
              )}
            </Portal>
            <View style={[styles.Landingcontainer, { paddingBottom: 0 }]}>
              <CoreBanner
                style={styles.banner}
                imgUrl={orgDetail?.[0]?.reg_banner}
              />
              <View
                style={[
                  {
                    marginHorizontal: 45,
                  },
                ]}
              >
                <View>
                  <CoreText
                    textTitle={
                      orgLaunguage?.home_title ||
                      "Kul att du vill sälja kläder med oss!"
                    }
                    style={[
                      styles.mainTitle,
                      {
                        paddingTop: 50,
                        color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                        textAlign: "center",
                      },
                    ]}
                    fontType={MontserratMedium}
                  />

                  <CoreText
                    textTitle=""
                    style={[
                      styles.headerSubTitle,
                      {
                        paddingTop: 10,
                        color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                        textAlign: "center",
                        display: "flex",
                        overflow: "visible",
                      },
                    ]}
                    fontType={MontserratMedium}
                  />
                </View>
              </View>
            </View>
            {organisation?.cashierIntegration == "boxnet" ? (
              <View />
            ) : (
              <View style={{ marginHorizontal: 22, marginBottom: 30 }}>
                <Button
                  btnTitle={
                    orgLaunguage?.home_button1 || "Jag vill sälja kläder"
                  }
                  // disabled={switchItemData}
                  // disableButtondata={switchItemData}
                  style={[
                    styles.signUpButton,
                    switchItemData && styles.buttonNone,
                  ]}
                  lineHeight={30}
                  onPress={() =>
                    navigation.navigate("Signup", { organisationId })
                  }
                />
              </View>
            )}

            <View
              style={[
                {
                  marginHorizontal: 45,
                },
              ]}
            >
              <View style={{ textAlign: "center" }}>
                <CoreText
                  textTitle={
                    orgLaunguage?.home_subtitle ||
                    "Har du redan lämnat in kläder och har ett konto hos oss?"
                  }
                  style={[styles.subTitle, { textAlign: "center" }]}
                  fontType={MontserratExtraLight}
                />
              </View>
            </View>

            <View style={{ marginHorizontal: 22, marginBottom: 30 }}>
              <Button
                btnTitle={orgLaunguage?.home_button2 || "Logga in"}
                style={[styles.signUpButton]}
                lineHeight={30}
                onPress={() =>
                  navigation.navigate(
                    organisation?.cashierIntegration != "boxnet"
                      ? "Login"
                      : "LoginExternal",
                    {
                      organisationId,
                    }
                  )
                }
              />
            </View>
          </ScrollView>
          <CoreFooter />
        </KeyboardAvoidingView>
      </SafeAreaView>
    </Provider>
  );
};
export default HomePage;
