import React from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
  useWindowDimensions,
  Image,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import styles from "../components/style/styles.js";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import SwiperAccept from "../components/swiper/SwiperAccept";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const BagJournyLandingCriteria = ({ navigation }) => {
  const divStyle = useWindowDimensions().width;

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        backgroundColor: Colors.BLACK,
      }}
    >
      <ScrollView>
        <View style={[styles.Landingcontainer, { paddingBottom: 0 }]}>
          <View
            style={[
              styles.containerBody,
              { alignItems: "flex-end", justifyContent: "flex-end" },
            ]}
          >
            <View style={{ marginTop: 33, marginBottom: 15 }}>
              <AntDesign
                name="close"
                size={24}
                color="rgba(255, 255, 255, 0.4)"
              />
            </View>
          </View>
          <View style={styles.contentHeader}>
            <View style={styles.contentHeaderBody}>
              <Image
                style={{ height: 24, width: 40, marginTop: 40 }}
                source={require("../assets/images/bagJourny.png")}
              />
              <CoreText
                textTitle="Riktlinjer för inlämning av begagnade kläder"
                style={[
                  styles.mainTitle,
                  { marginTop: 40, color: Colors.WHITE },
                ]}
                fontType={MontserratMedium}
              />
            </View>
          </View>

          <View style={[styles.cardContainer, { marginBottom: 0 }]}>
            <SwiperAccept />
          </View>
        </View>
      </ScrollView>
      <TouchableOpacity
        style={[
          styles.button,
          {
            marginHorizontal: 20,
            marginBottom: 21,
            marginTop: 0,
            backgroundColor: Colors.WHITE,
          },
        ]}
        onPress={() => navigation.navigate("AddAddress")}
      >
        <CoreText
          textTitle="Fortsätt"
          style={[styles.btntextstyle, { lineHeight: 30, color: Colors.BLACK }]}
          fontType={MontserratBold}
        />
      </TouchableOpacity>
    </SafeAreaView>
  );
};
export default BagJournyLandingCriteria;
