import React, { useEffect } from "react";
import {
  View,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Card } from "react-native-paper";
import { AntDesign } from "@expo/vector-icons";
import styles from "../components/style/styles.js";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import Button from "../components/Button";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");

const notAcceptedItems = [
  {
    cardtitle: "Trasiga, fläckiga kläder",
    subTitle: "Vi tar endast emot kläder som är välbevarade.",
    image: require("../assets/images/jacket1.png"),
  },
  {
    cardtitle: "Smutsiga eller fläckiga kläder",
    subTitle: "Vi tar endast emot kläder som är nytvättade och fläckfria.",
    image: require("../assets/images/jacket2.png"),
  },
];

const LandingCriteraNotAccept = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const rejectInstruction = route.params.rejectInstruction;
  const rejectImgUrl = route.params.image;
  const subDetail = route.params.detail;

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View style={styles.landingback}>
            <AntDesign
              name="left"
              size={24}
              color={Colors.LIGHTEST_GRAY}
              onPress={() => navigation.goBack()}
            />
          </View>
          <View style={[styles.crossIconSection, { marginBottom: 24 }]}>
            <View style={styles.contentHeaderBody}>
              <View>
                <AntDesign
                  name="closecircle"
                  size={33}
                  style={styles.closeCircle}
                  color={Colors.RED}
                />
              </View>
              <View>
                <CoreText
                  textTitle="Vi tar inte emot följande plagg"
                  style={[styles.mainTitle, { marginTop: 34 }]}
                  fontType={MontserratMedium}
                />
                <CoreText
                  textTitle="För att vara säker på att vi kommer acceptera dina kläder behöver du följa dessa riktlinjer:"
                  style={[styles.subTitle, { marginTop: 16 }]}
                  fontType={MontserratExtraLight}
                />
              </View>
            </View>
          </View>
          <View style={styles.acceptCardBody}>
            <Card
              style={[styles.acceptCardStyle, { marginBottom: 12 }]}
              // key={index}
            >
              <Card.Cover
                source={{ uri: rejectImgUrl }}
                style={styles.cardCoverStyle}
              />
              <View style={{ marginHorizontal: 28 }}>
                <Card.Title
                  subtitleNumberOfLines={2}
                  style={[styles.cardTitleStyle, { marginTop: 4 }]}
                  leftStyle={styles.otherLeftStyle}
                  titleStyle={{ marginLeft: -4 }}
                  title={
                    <CoreText
                      textTitle={rejectInstruction}
                      style={[styles.acceptSubtitleStyle]}
                      fontType={MontserratRegular}
                    />
                  }
                  titleNumberOfLines={2}
                  left={() => (
                    <AntDesign
                      name="closecircle"
                      size={20}
                      style={[{ marginTop: 2, marginLeft: -2 }]}
                      color={Colors.RED}
                    />
                  )}
                />
              </View>
              <View style={{ marginLeft: 24, marginTop: -6 }}>
                <Card.Title
                  style={styles.cardTitleStyle}
                  leftStyle={styles.otherLeftStyle}
                  title={
                    <CoreText
                      textTitle={subDetail}
                      style={[styles.subtitleStyle]}
                      fontType={MontserratRegular}
                    />
                  }
                  titleNumberOfLines={2}
                />
              </View>
            </Card>
          </View>
        </View>
      </ScrollView>
      <View style={styles.btnSection}>
        <Button
          btnTitle="Fortsätt"
          style={[
            {
              marginTop: 12,
            },
          ]}
          onPress={() =>
            navigation.navigate("Resale", {
              organisationId: pickOrg,
            })
          }
          lineHeight={30}
        />
      </View>
    </SafeAreaView>
  );
};
export default LandingCriteraNotAccept;
