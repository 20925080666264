import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  Image,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
} from "react-native";
import {
  AntDesign,
  MaterialIcons,
  Ionicons,
  FontAwesome,
  Feather,
} from "@expo/vector-icons";
import uuid from "react-native-uuid";
import { useSelector, useDispatch } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  setProductDetails,
  setCustomer,
} from "../components/redux/actions/prodActions";
import EditableData from "../components/EditableData";
import OrgTemplate from "./OrgTemplate";
import { Camera } from "expo-camera";
import * as ImagePicker from "expo-image-picker";
import styles from "../components/style/styles";
import Button from "../components/Button";
import Colors from "../constants/Colors";
import {
  putProductDetails,
  postProductDetails,
  isEmailExist,
} from "../actions";
import MutlipleImageCamera from "../components/MutlipleImageCamera";
import { setAuthHeader } from "../axiosApi";
import Header from "../components/core/Header";
import {
  ArticleStatus,
  Status,
  ReadyToSaleStatus,
} from "../sharedLib/sourcingItemStatus.js";
import CoreFooter from "../components/core/CoreFooter";
import CoreText from "../components/core/CoreText";
import { TextInput } from "react-native-paper";
import MontserratMedium from "../assets/Font/Montserrat-Medium.ttf";
import RNPickerSelect from "react-native-picker-select";

let camera = React.createRef();
let infoArray = [];
let fetchId;
let showGender = true
let showCategory = true
let showBrand = true

const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");
const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");

const AddItemProductType = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const gender = route.params?.gender;
  const clothType = route.params?.clothType;
  const brandType = route.params?.brandType;
  const priceRange = route.params?.priceInterval;
  const [isLoad, setIsLoad] = useState(true);
  const [startCamera, setStartCamera] = React.useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [capturedImage, setCapturedImage] = useState();
  const [flashMode, setFlashMode] = useState(Camera.Constants.FlashMode.off);
  const [image, setImage] = useState();
  const [isImageLoad, setIsImageLoad] = useState(false);
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const customers = useSelector((state) => state.prodReducer.customerDetail);
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const dispatch = useDispatch();
  const requiredImageField = orgDetail?.[0]?.requiredImageField;
  const [selectedIndex, setSelectedIndex] = useState();
  const [textValue, setTextValue] = useState("");
  const [genderText, setGenderText] = useState("");
  const [brandName, setBrandName] = useState("");
  const [imageUploadDisable, setImageUploadDisable] = useState(false);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [selectValue, setSelectValue] = useState(0);
  const [permissionIos, setPermissionIos] = useState();
  const [skuNumber, setSkuNumber] = useState("");
  const [skickName, setSkickName] = useState("");
  const [colourName, setColourName] = useState("");
  const [conditionName, setConditionName] = useState("");
  const [modelName, setModelName] = useState("");
  const [sizeName, setSizeName] = useState("");
  const [categoryName, setCategoryName] = useState("")
  const categories = AsyncStorage.getItem("categorie");
  const [errorMsg, setErrorMsg] = useState("");

  const customOptionsData = orgSetting?.customOptions
    ? JSON.parse(orgSetting?.customOptions)
    : {};
  const colors = customOptionsData.color || [];

  const customPageList = orgDetail[0]?.customPages
    ? JSON.parse(orgDetail[0]?.customPages)
    : {};

  const getColorData = colors.map((color) => ({
    label: color,
    value: color,
  }));

  const sizes = customOptionsData.size || [];
  const getSizeData = sizes.map((size) => ({
    label: size,
    value: size,
  }));

  const categorys = customOptionsData.category || [];
  const getCategoryData = categorys.map((category) => ({
    label: category,
    value: category,
  }));
  useEffect(() => {
    if (orgDetail[0]?.urlPath === 'sandqvist') {
      if (customPageList?.isHideItemCart) {
        showGender = false
      }
      if (customPageList?.isHideOption) {
        showCategory = false
      }
      if (customPageList?.isHideSearch) {
        showBrand = false
      }
    }
  }, [customPageList])
  const customColor = customOptionsData?.color?.length > 0;
  const customSize = customOptionsData?.size?.length > 0;
  const customCategory = customOptionsData?.category?.length > 0;

  const organisationSourceItem = orgDetail?.[0]?.customAttribute?.map(
    (item) => item.name
  );
  const regex = /[^0-9]/g;
  const orgLaunguage = useSelector((state) => state.prodReducer.languageData);
  const typeCentra = orgDetail?.[0]?.integration?.type === "Centra"
  const selfServiceFlow = orgDetail?.[0]?.selfServiceFlow;
  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };
  const device = getMobileOperatingSystem() === "Android";

  const [permission, requestPermission] = device
    ? Camera.useCameraPermissions()
    : "";

  useEffect(async () => {
    const _token = await AsyncStorage.getItem("token");
    setAuthHeader(_token);
    setIsLoad(false);
  }, []);

  const metaField = {
    SKU: skuNumber,
    Skick: skickName,
    Colour: colourName,
    Condition: conditionName,
    Model: modelName,
    Size: sizeName,
    Category: categoryName
  };

  const handleClick = async () => {
    setIsLoad(true);
    const value = priceRange || "0-0";
    const arr = value.split("-");
    const maxValue = Math.max(...arr);
    const getCustomer = {
      email: customers?.email || customers?.[0]?.email,
      // phoneNumber: customers?.phoneNumber || customers[0]?.phoneNumber,
      id: customers?.id,
    };
    let data = {
      photo: infoArray,
      organisation_id: orgDetail?.[0]?.urlPath,
      gender: genderText || gender,
      status: Status.Pending,
      article_status: ArticleStatus.AtCustomer,
      sale_status: ReadyToSaleStatus.InProcess,
      brand: brandName || brandType,
      customer_details: getCustomer,
      customer_id: customers?.id || customers?.[0]?.id,
      reseller_id: customers?.id || customers?.[0]?.id,
      url: "",
      approved: "",
      offer_status: "offer_pending",
      price_interval: priceRange || 0,
      category: textValue || clothType,
      price: (maxValue * orgDetail?.[0]?.org_comission) / 100,
      location_id: "",
      type: "",
      description: description,
      vat: 25,
      requested_assortment: categories ? true : false,
    };

    if (selfServiceFlow) {
      data.status = Status.Accepted;
      data.description = route.params?.description || data.description;
      data.price = parseFloat(route.params?.price || price || data.price);
      data.price_interval =
        route.params?.price_interval || data.price_interval || "0-0";
    }
    if (typeCentra && !customOptionsData?.isShowAllItem) {
      data = { ...data, meta_fields: typeCentra && JSON.stringify(metaField) }
    }
    await putProductDetails(data, customers?.isLogin).then((resp) => {
      fetchId = resp.data;
      dispatch(setProductDetails(resp.data));
    });
    setIsLoad(false);
    if ((customers?.isLogin || customers?.[0]?.isLogin) === true) {
      navigation.navigate("AddItemSuccess", {
        cartItem: infoArray?.[0]?.url,
        textValue,
        organisationId: pickOrg,
      });
    } else {
      isEmailExist(customers?.email, pickOrg)
        .then(({ data }) => {
          if (data) {
            if (data?.email_exists) {
              const setUser = { ...getCustomer, alreadyUser: true };
              dispatch(setCustomer(setUser));
              navigation.navigate("Login", {
                organisationId: pickOrg,
                sourcedItemId: fetchId,
              });
            } else {
              navigation.navigate("CreateAccount", {
                cartItem: infoArray?.[0]?.url,
                textValue,
                organisationId: pickOrg,
                sourcedItemId: fetchId,
              });
            }
          }
        })
        .catch((error) => {
          console.log("resp....", error);
        });
    }
    AsyncStorage.removeItem("categorie");
  };

  const handleConditionChange = (value) => {
    setErrorMsg("");
    if (regex.test(value)) {
      setErrorMsg("vänligen ange endast nummer");
    } else if (value > 5) {
      setErrorMsg("vänligen ange ett antal mindre än 5");
    } else {
      setConditionName(value);
      setErrorMsg("");
    }
  };
  const uploadImage = async (image, isFromCamra = false) => {
    setIsImageLoad(true);
    const data = {
      name: uuid.v4().toString(),
      imageBinary: isFromCamra ? image?.base64 : image?.uri,
    };
    if (capturedImage || image) {
      setStartCamera(false);
      setCapturedImage(image);
      await postProductDetails(data)
        .then((resp) => {
          infoArray[selectedIndex] = {
            name: requiredImageField[selectedIndex]?.subtitle,
            url: resp.data.Location,
          };
          setIsImageLoad(false);
          setImageUploadDisable(true);
        })
        .catch((error) => { });
    } else {
      setStartCamera(true);
    }
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!result.cancelled) {
      setImage(`data:image/jpeg;base64,${result.base64}`);
    }
  };

  const __takePicture = async () => {
    if (!camera) return;
    const photo = await camera.takePictureAsync();
    setPreviewVisible(true);
    setCapturedImage(photo);
  };

  const __retakePicture = (isCamara = false) => {
    setCapturedImage(null);
    setPreviewVisible(false);
    setImage(null);
    if (isCamara) {
      setStartCamera(false);
    } else {
      setStartCamera(true);
    }
  };

  const __RestartCamera = async (index) => {
    if (!device) {
      const status = await Camera.requestCameraPermissionsAsync();
      setPermissionIos(status);
    }
    setSelectedIndex(index);
    setPreviewVisible(null);
    setCapturedImage(null);
    setImage(null);
    setStartCamera(true);
    // Alert.alert("Access denied");
  };
  const CameraPreview = ({ photo }) => {
    return (
      <View
        style={{
          backgroundColor: "transparent",
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <ImageBackground
          source={{ uri: photo && photo.uri }}
          style={{
            flex: 1,
          }}
        />
        <TouchableOpacity
          onPress={() => {
            __retakePicture(false);
          }}
          style={styles.closeicon}
        >
          <AntDesign
            name="close"
            size={20}
            color={Colors.WHITE}
            style={{ padding: 11 }}
          />
        </TouchableOpacity>
        <View style={styles.photogallery}>
          <Image style={styles.photostyle} source={capturedImage} />
        </View>
        <View style={styles.photoupload}>
          <TouchableOpacity
            onPress={() => uploadImage(capturedImage, true)}
            style={[
              styles.imagebtn,
              {
                backgroundColor: orgDetail?.[0]?.primaryColor || Colors.BLACK,
              },
            ]}
          >
            <Text
              style={{
                paddingHorizontal: 1,
                paddingVertical: 7,
                color: "#fff",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Feather
                name="upload"
                size={24}
                color={Colors.WHITE}
                style={{ paddingRight: 5 }}
              />
              Använd den här bilden
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  const permissionBlock = (
    <View
      style={{
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        top: 100,
        zIndex: 1,
      }}
    >
      <Text
        style={{
          paddingHorizontal: 20,
          fontWeight: "bold",
          color: "#000",
          marginBottom: 20,
          fontSize: 18,
        }}
      >
        Åtkomst till kameran
      </Text>
      <Text
        style={{
          paddingHorizontal: 20,
          fontWeight: "bold",
          color: "#000",
          marginBottom: 20,
          fontSize: 16,
        }}
      >
        För att fortsätta så måste du ta en bild på ditt plagg. Tillåt
        webbläsaren att komma åt kameran i inställningarna på din telefon,
        därefter kan du behöva starta om
      </Text>
    </View>
  );

  if (selfServiceFlow) {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        }}
      >
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          enabled
        >
          <Header
            navigation={navigation}
            route={route}
            isHeaderItem
            progress={0.8}
            steps={5}
          />
          <ScrollView>
            <View
              style={{
                flex: 1,
                color: "#0F0F0F",
              }}
            >
              <View style={styles.searchBody}>
                <View style={{ paddingHorizontal: 20 }}>
                  <CoreText
                    textTitle={
                      orgLaunguage?.AddPrice_title ||
                      "Ungefär vad var nypris för plagget"
                    }
                    style={[
                      styles.Title,
                      {
                        color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                        textAlign: "center",
                      },
                    ]}
                    fontType={MontserratMedium}
                  />
                </View>
              </View>
              <View style={{ paddingHorizontal: 20 }}>
                <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                  <CoreText
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                    textTitle="description"
                    fontType={MontserratLight}
                  />
                  <TextInput
                    label={
                      <CoreText
                        textTitle="Please fill in a description of your item"
                        style={[styles.input]}
                        fontType={MontserratRegular}
                        isInput
                      />
                    }
                    style={[styles.inputFiledstyle]}
                    value={description}
                    keyboardType="default"
                    placeholderTextColor="#878787"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </View>
                <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                  <CoreText
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                    textTitle="Price"
                    fontType={MontserratLight}
                  />
                  <TextInput
                    label={
                      <CoreText
                        textTitle="Please fill in the price"
                        style={[styles.input]}
                        fontType={MontserratRegular}
                        isInput
                      />
                    }
                    style={[styles.inputFiledstyle]}
                    value={price}
                    keyboardType="default"
                    placeholderTextColor="#878787"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </View>
                <View
                  style={[
                    styles.btnSection,
                    styles.footerButtonSection,
                    { marginBottom: 30 },
                  ]}
                >
                  <Button
                    btnTitle={"Fortsätt"}
                    disabled={!price}
                    disableButtondata={!price}
                    onPress={handleClick}
                    textstyle={price ? styles.btntext : styles.disableBtnStyle}
                    style={[
                      styles.footerButtonSection,
                      {
                        marginHorizontal: 20,
                        marginBottom: 30,
                      },
                    ]}
                  />
                </View>
              </View>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    );
  }
  return (
    <View
      style={{
        flex: 1,
        color: "#0F0F0F",
      }}
    >
      {(startCamera && permission) || (startCamera && permissionIos) ? (
        previewVisible && capturedImage ? (
          <CameraPreview photo={capturedImage} />
        ) : image ? (
          <View style={styles.imagemainblock}>
            {image && (
              <>
                <Image
                  source={{ uri: image || capturedImage?.uri }}
                  style={{
                    width: 400,
                    height: 400,
                    resizeMode: "center",
                  }}
                />
                <TouchableOpacity
                  onPress={() => {
                    __retakePicture(false);
                  }}
                  style={styles.closeicon}
                >
                  <AntDesign name="close" size={20} color={Colors.WHITE} />
                </TouchableOpacity>
                <View style={styles.croupimg}>
                  <TouchableOpacity
                    onPress={() => uploadImage({ uri: image })}
                    style={[
                      styles.croupuploadicon,
                      {
                        backgroundColor:
                          orgDetail?.[0]?.primaryColor || Colors.BLACK,
                      },
                    ]}
                  >
                    <Text
                      style={{
                        paddingHorizontal: 1,
                        paddingVertical: 7,
                        color: "#fff",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Feather
                        name="upload"
                        size={24}
                        color={Colors.WHITE}
                        style={{ paddingRight: 5 }}
                      />
                      Använd den här bilden
                    </Text>
                  </TouchableOpacity>
                </View>
              </>
            )}
          </View>
        ) : (
          <Camera
            flashMode={flashMode}
            style={{ flex: 1, width: "100%" }}
            ref={(r) => {
              camera = r;
            }}
          >
            <TouchableOpacity
              onPress={() =>
                setFlashMode(
                  flashMode === Camera.Constants.FlashMode.off
                    ? Camera.Constants.FlashMode.torch
                    : Camera.Constants.FlashMode.off
                )
              }
              style={[styles.flash, { zIndex: 11 }]}
            >
              {flashMode === Camera.Constants.FlashMode.off ? (
                <MaterialIcons
                  name="flash-off"
                  size={20}
                  color={Colors.WHITE}
                />
              ) : (
                <MaterialIcons name="flash-on" size={20} color={Colors.WHITE} />
              )}
            </TouchableOpacity>
            <TouchableOpacity
              onPress={__retakePicture}
              style={[styles.closeicon, { zIndex: 11 }]}
            >
              <AntDesign name="close" size={20} color={Colors.WHITE} />
            </TouchableOpacity>

            {device
              ? !permission?.granted && permissionBlock
              : !permissionIos?.granted && permissionBlock}

            <View style={[styles.photogallery, { zIndex: 11 }]}>
              <View
                style={{
                  top: 16,
                }}
              >
                <TouchableOpacity
                  title="Pick an image from camera roll"
                  onPress={pickImage}
                  style={[
                    styles.imagebtn,
                    {
                      backgroundColor:
                        orgDetail?.[0]?.primaryColor || Colors.BLACK,
                    },
                  ]}
                >
                  <Text style={{ paddingHorizontal: 1, paddingVertical: 7 }}>
                    <Ionicons
                      name="images-outline"
                      size={24}
                      color={Colors.WHITE}
                    />
                  </Text>
                </TouchableOpacity>
              </View>

              <View style={styles.takepicturepart}>
                <TouchableOpacity
                  onPress={__takePicture}
                  style={styles.takepicture}
                >
                  <FontAwesome
                    name="circle-thin"
                    size={48}
                    color="rgba(15, 15, 15, 0.2)"
                  />
                </TouchableOpacity>
              </View>
            </View>
          </Camera>
        )
      ) : (
        <SafeAreaView
          style={{
            flex: 1,
            paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
          }}
        >
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            enabled
          >
            <Header
              navigation={navigation}
              route={route}
              isHeaderItem
              progress={0.8}
              steps={5}
            />
            <ScrollView>
              {requiredImageField.map(
                (item, index) =>
                  // index < 2 && (
                  <View key={index}>
                    <MutlipleImageCamera
                      item={item}
                      subtitle={item.subtitle}
                      isImageLoad={isImageLoad}
                      orgDetail={orgDetail}
                      RestartCamera={__RestartCamera}
                      getImage={infoArray[index]}
                      index={index}
                      selectedIndex={selectedIndex}
                      infoArray={infoArray}
                    />
                  </View>
                // )
              )}
              {showGender &&
                <>
                  <EditableData
                    title={"kategori"}
                    inputvalue={gender}
                    testID="one_input"
                    borderColorprops={orgDetail?.[0]?.primaryColor || Colors.BLACK}
                    productsId={orgDetail?.[0]?.id.toString()}
                    setTextValue={setGenderText}
                    textValue={genderText}
                  />
                  <View style={styles.horizontalRole} />
                </>}
              {showCategory &&
                <>
                  <EditableData
                    title={"Plaggtyp"}
                    inputvalue={clothType}
                    testID="one_input"
                    borderColorprops={orgDetail?.[0]?.primaryColor || Colors.BLACK}
                    productsId={orgDetail?.[0]?.id.toString()}
                    setTextValue={setTextValue}
                    textValue={textValue}
                  />
                  <View style={styles.horizontalRole} />
                </>
              }
              {showBrand &&
                <>
                  <EditableData
                    title={"Varumärke"}
                    inputvalue={brandType}
                    testID="two_input"
                    isSecond
                    borderColorprops={orgDetail?.[0]?.primaryColor || Colors.BLACK}
                    productsId={orgDetail?.[0]?.id.toString()}
                    setTextValue={setBrandName}
                    textValue={brandName}
                  />
                  <View style={styles.horizontalRole} /></>
              }
              {typeCentra && !customOptionsData?.isShowAllItem && (
                <>
                  {!customOptionsData?.isShowSku &&
                    <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                      <CoreText
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                        textTitle="SKU"
                        fontType={MontserratLight}
                      />
                      <TextInput
                        label={
                          <CoreText
                            textTitle="Type SKU"
                            style={[styles.input]}
                            fontType={MontserratRegular}
                            isInput
                          />
                        }
                        style={[styles.inputFiledstyle]}
                        value={skuNumber}
                        keyboardType="default"
                        placeholderTextColor="#878787"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        onChange={(e) => setSkuNumber(e.target.value)}
                      />
                    </View>
                  }
                  {!customOptionsData?.isShowSkick &&
                    <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                      <CoreText
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                        textTitle="Skick"
                        fontType={MontserratLight}
                      />
                      <TextInput
                        label={
                          <CoreText
                            textTitle="type skick name"
                            style={[styles.input]}
                            fontType={MontserratRegular}
                            isInput
                          />
                        }
                        style={[styles.inputFiledstyle]}
                        value={skickName}
                        keyboardType="default"
                        placeholderTextColor="#878787"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        onChange={(e) => setSkickName(e.target.value)}
                      />
                    </View>
                  }

                  {!customOptionsData?.isShowColor &&
                    <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                      <CoreText
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                        textTitle="Colour"
                        fontType={MontserratLight}
                      />
                      <View>
                        {customColor ? (
                          <RNPickerSelect
                            placeholder={{
                              label: "Select Colour",
                            }}
                            items={getColorData}
                            onValueChange={(value) =>
                              setColourName(value)
                            }
                            useNativeAndroidPickerStyle={false}
                            value={colourName}
                            pickerProps={{
                              style: {
                                padding: 10,
                                fontSize: 15,
                                borderWidth: 1,
                                borderColor: "#DCDCE0",
                                borderRadius: 5,
                              },
                            }}
                          />
                        ) : (
                          <TextInput
                            label={
                              <CoreText
                                textTitle="type colour text"
                                style={[styles.input]}
                                fontType={MontserratRegular}
                                isInput
                              />
                            }
                            style={[styles.inputFiledstyle]}
                            value={colourName}
                            keyboardType="default"
                            placeholderTextColor="#878787"
                            underlineColor="transparent"
                            activeUnderlineColor="transparent"
                            onChange={(e) => setColourName(e.target.value)}
                            maxLength={256}
                          />
                        )}

                      </View>
                    </View>
                  }
                  {!customOptionsData?.isShowSize &&
                    <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                      <CoreText
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                        textTitle="Size"
                        fontType={MontserratLight}
                      />
                      <View>
                        {customSize ? (
                          <RNPickerSelect
                            placeholder={{
                              label: "Select Size",
                            }}
                            items={getSizeData}
                            onValueChange={(value) =>
                              setSizeName(value)
                            }
                            useNativeAndroidPickerStyle={false}
                            value={sizeName}
                            pickerProps={{
                              style: {
                                padding: 10,
                                fontSize: 15,
                                borderWidth: 1,
                                borderColor: "#DCDCE0",
                                borderRadius: 5,
                              },
                            }}
                          />
                        ) : (
                          <TextInput
                            label={
                              <CoreText
                                textTitle="type colour text"
                                style={[styles.input]}
                                fontType={MontserratRegular}
                                isInput
                              />
                            }
                            style={[styles.inputFiledstyle]}
                            value={sizeName}
                            keyboardType="default"
                            placeholderTextColor="#878787"
                            underlineColor="transparent"
                            activeUnderlineColor="transparent"
                            onChange={(e) => setSizeName(e.target.value)}
                            maxLength={256}
                          />
                        )}

                      </View>
                    </View>
                  }
                  {!customOptionsData?.isShowCategory &&
                    <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                      <CoreText
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                        textTitle="Category"
                        fontType={MontserratLight}
                      />
                      <View>
                        {customCategory ? (
                          <RNPickerSelect
                            placeholder={{
                              label: "Select Category",
                            }}
                            items={getCategoryData}
                            onValueChange={(value) =>
                              setCategoryName(value)
                            }
                            useNativeAndroidPickerStyle={false}
                            value={categoryName}
                            pickerProps={{
                              style: {
                                padding: 10,
                                fontSize: 15,
                                borderWidth: 1,
                                borderColor: "#DCDCE0",
                                borderRadius: 5,
                              },
                            }}
                          />
                        ) : (
                          <TextInput
                            label={
                              <CoreText
                                textTitle="type colour text"
                                style={[styles.input]}
                                fontType={MontserratRegular}
                                isInput
                              />
                            }
                            style={[styles.inputFiledstyle]}
                            value={categoryName}
                            keyboardType="default"
                            placeholderTextColor="#878787"
                            underlineColor="transparent"
                            activeUnderlineColor="transparent"
                            onChange={(e) => setCategoryName(e.target.value)}
                            maxLength={256}
                          />
                        )}

                      </View>
                    </View>
                  }

                  {!customOptionsData?.isShowCondition &&
                    <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                      <CoreText
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                        textTitle="Condition"
                        fontType={MontserratLight}
                      />
                      <TextInput
                        label={
                          <CoreText
                            textTitle="type condition"
                            style={[styles.input]}
                            fontType={MontserratRegular}
                            isInput
                          />
                        }
                        style={[styles.inputFiledstyle]}
                        // value={conditionName}
                        keyboardType="numeric"
                        placeholderTextColor="#878787"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        onChangeText={(e) => handleConditionChange(e)}
                      />
                      {errorMsg && (
                        <View style={{ marginHorizontal: 20 }}>
                          <CoreText
                            textTitle={errorMsg}
                            style={[styles.error]}
                            fontType={MontserratRegular}
                          />
                        </View>
                      )}
                    </View>
                  }
                  {!customOptionsData?.isShowModal &&
                    <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                      <CoreText
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                        textTitle="Model"
                        fontType={MontserratLight}
                      />
                      <TextInput
                        label={
                          <CoreText
                            textTitle="type model text"
                            style={[styles.input]}
                            fontType={MontserratRegular}
                            isInput
                          />
                        }
                        style={[styles.inputFiledstyle]}
                        value={modelName}
                        keyboardType="default"
                        placeholderTextColor="#878787"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        onChange={(e) => setModelName(e.target.value)}
                        maxLength={256}
                      />
                    </View>
                  }
                </>
              )}

              <OrgTemplate
                descriptionData={description}
                setDescription={setDescription}
                setSelectValue={setSelectValue}
                selectValue={selectValue}
                organisationSourceItem={organisationSourceItem}
              />
            </ScrollView>
            <View
              style={[
                styles.btnSection,
                styles.footerButtonSection,
                { marginBottom: 30 },
              ]}
            >
              <Button
                btnTitle={
                  capturedImage || image
                    ? orgLaunguage?.addproduct_button ||
                    "Skicka in för övervägning"
                    : "Fortsätt"
                }
                capturedImage={capturedImage || image}
                image={image}
                disabled={!imageUploadDisable || errorMsg}
                disableButtondata={!imageUploadDisable || errorMsg}
                onPress={handleClick}
                textstyle={
                  imageUploadDisable || errorMsg
                    ? styles.btntext
                    : styles.disableBtnStyle
                }
                isLoad={isLoad}
              />
            </View>
            <CoreFooter />
          </KeyboardAvoidingView>
        </SafeAreaView>
      )}
    </View>
  );
};
export default AddItemProductType;
