import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { setCustomer } from "../components/redux/actions/prodActions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import uuid from "react-native-uuid";
import styles from "../components/style/styles.js";
import { TextInput } from "react-native-paper";
import { engageCustomer } from "../actions";
import { ScrollView } from "react-native-gesture-handler";
import CoreBanner from "../components/core/CoreBanner";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import CoreLoader from "../components/core/CoreLoader";
import Header from "../components/core/Header";
import Colors from "../constants/Colors";
import CoreFooter from "../components/core/CoreFooter";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");

const Signup = ({ navigation, route }) => {
  const pickUser = route.params.organisationId;
  const [textValue, setTextValue] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoadData, setIsLoadData] = useState(true);
  const [loadCustomData, setLoadCustomData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const re = /^[0-9\b]+$/;
  const reg =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\+[0-9]{12})+$/;

  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgLaunguage = useSelector((state) => state.prodReducer.languageData);
  const dispatch = useDispatch();

  const onChange = (value) => {
    if (!value) {
      setIsError(true);
      setTextValue("");
    } else {
      setIsError(false);
      if (re.test(value)) {
        const numberFormat = value.replace(
          /^(.{3})(.{3})(.{2})(.{2})$/,
          "$1 $2 $3 $4"
        );
        setTextValue(numberFormat);
      } else {
        setTextValue(value);
      }
    }
  };

  useEffect(() => {
    if (textValue === "") {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, []);

  useEffect(async () => {
    if (orgDetail) {
      setIsLoadData(false);
    } else {
      setIsLoadData(true);
    }
  }, []);

  const handleNext = async (user) => {
    setLoadCustomData(true);
    if (textValue === "") {
      setIsError(true);
      setErrorMessage("Ange en epost address");
      setLoadCustomData(false);
    } else if (!reg.test(textValue.split(" ").join(""))) {
      setIsError(true);
      setErrorMessage("Ange en epost address.");
      setLoadCustomData(false);
    } else {
      // Verify data
      const organisationId = await AsyncStorage.getItem("organisation");
      engageCustomer(pickUser || organisationId, textValue);
      setErrorMessage("");
      const cutomerData = {
        email: user,
        id: uuid.v4().toString(),
        isLogin: false,
      };
      dispatch(setCustomer(cutomerData));
      AsyncStorage.setItem("customer", JSON.stringify(cutomerData));
      navigation.navigate("NewCritera", { organisationId: pickUser || organisationId });
      setLoadCustomData(false);
    }
  };

  // submit data whe we press enter
  const handleKeyPress = (keyName) => {
    if (keyName === "Enter") {
      handleNext(textValue);
    }
  };

  return isLoadData ? (
    <CoreLoader
      style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    />
  ) : (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <Header navigation={navigation} route={route} isHeader />
        <ScrollView>
          <View style={[styles.Landingcontainer]}>
            <CoreBanner
              style={styles.banner}
              imgUrl={orgDetail?.[0]?.reg_banner}
            />
            <View
              style={[
                styles.alignItems,
                styles.justifyContent,
                {
                  marginHorizontal: 45,
                },
              ]}
            >
              <View>
                <CoreText
                  textTitle={
                    orgDetail?.[0]?.reg_msg ||
                    orgLaunguage?.signup_title ||
                    "Välkommen!"
                  }
                  style={[
                    styles.mainTitle,
                    {
                      paddingTop: 50,
                      color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                      textAlign: "center",
                    },
                  ]}
                  fontType={MontserratMedium}
                />
                <CoreText
                  textTitle={
                    orgLaunguage?.signup_subtitle ||
                    "Skriv in din e-postadress för att komma igång att sälja kläder"
                  }
                  style={[
                    styles.subTitle,
                    { marginBottom: 30, paddingTop: 15, textAlign: "center" },
                  ]}
                  fontType={MontserratExtraLight}
                />
              </View>
            </View>
          </View>
          <View style={[styles.btnsection, { marginTop: 10 }]}>
            <View style={{ marginBottom: 18 }}>
              <TextInput
                label={
                  <CoreText
                    textTitle="Ange din epost"
                    style={[styles.input]}
                    fontType={MontserratRegular}
                    isInput
                  />
                }
                style={[styles.inputFiledstyle]}
                value={textValue}
                keyboardType="default"
                testID="telenumber"
                placeholderTextColor="#878787"
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                onChangeText={(text) => onChange(text)}
                onKeyPress={(e) => handleKeyPress(e.key)}
              />
              {isError && (
                <CoreText
                  textTitle={isError && errorMessage}
                  style={[isError && styles.error]}
                  fontType={MontserratRegular}
                />
              )}
            </View>
          </View>

          <CoreText
            textTitle="E-postadress används för att kontakta dig i processen"
            style={[styles.headerSubTitle, { textAlign: "center" }]}
            fontType={MontserratExtraLight}
          />
        </ScrollView>
        <View
          style={[
            styles.btnsection,
            styles.footerButtonSection,
            { marginTop: 10, marginBottom: 30 },
          ]}
        >
          <Button
            isLoad={loadCustomData}
            btnTitle={orgLaunguage?.signup_button || "Fortsätt"}
            isError={isError}
            issinup
            disableButtondata={isError || !textValue}
            disabled={isError || !textValue}
            textstyle={[
              !isError ? styles.btntextstyle : styles.disableBtnStyle,
              { lineHeight: 36 },
            ]}
            onPress={() => handleNext(textValue)}
          />
        </View>
        <CoreFooter />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
export default Signup;
