// If you are not familiar with React Navigation, check out the "Fundamentals" guide:
// https://reactnavigation.org/docs/getting-started
import { useState, useEffect } from "react";
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from "@react-navigation/native";
import { Text } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { Provider } from "react-redux";

import store from "../components/redux/store";
import NotFoundScreen from "../screens/NotFoundScreen";
import SelectOrganisation from "../screens/SelectOrganisation";
import Signup from "../screens/Signup";
import HomePage from "../screens/HomePage";
import Login from "../screens/Login";
import LoginExternal from "../screens/LoginExternal";
import LandingCritera from "../screens/LandingCritera";
import NewCritera from "../screens/NewCritera";
import AddItemCart from "../screens/AddItemCart";
import Additem from "../screens/Additem";
import Additemoption from "../screens/Additemoption";
import AddItemSearch from "../screens/AddItemSearch";
import AddPrice from "../screens/AddPrice";
import AddItemProductType from "../screens/AddItemProductType";
import CreateAccount from "../screens/CreateAccount";
import AddItemSuccess from "../screens/AddItemSuccess";
import DropOffItem from "../screens/DropOffItem";
import DropOffItemAccept from "../screens/DropOffItemAccept";
import ItemSoid from "../screens/ItemSoid";
import LandingCriteraNotAccept from "../screens/LandingCriteraNotAccept";
import LandingCriteraAccept from "../screens/LandingCriteraAccept";
import Resale from "../screens/Resale";
import DropOffItemLocation from "../screens/DropOffItemLocation";
import AddItemCancle from "../screens/AddItemCancle";
import Collection from "../screens/Collection";
import BagJournyLandingCriteria from "../screens/BagJournyLandingCriteria";
import AddAddress from "../screens/AddAddress";
import BookedBag from "../screens/BookedBag";
import ClothBagCollection from "../screens/ClothBagCollection";
import ItemDetails from "../screens/ItemDetails";
import DropOffBagItem from "../screens/DropOffBagItem";
import SoldClothBagCollection from "../screens/SoldClothBagCollection";
import SoldClothBagCounterOffer from "../screens/SoldClothBagCounterOffer";
import SoldClothBagCollectionIntegration from "../screens/SoldClothBagCollectionIntegration";
import RetailerHome from "../screens/RetailerHome";
import RetailItemDetails from "../screens/RetailItemDetails";
import QRcodeScanner from "../screens/QRcodeScanner";
import RetailItemProductType from "../screens/RetailItemProductType";
import CommissionWithdraw from "../screens/CommissionWithdraw";
import ContactUs from "../screens/ContactUs";
import Register from "../screens/Register";
import ZimplerStatus from "../screens/ZimplerStatus";
import TermsCondition from "../screens/TermsCondition";
import ChatScreen from "../screens/ChatScreen"
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Navigation({ colorScheme }) {
  const MyTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: "#FFF",
    },
  };

  const linking = {
    config: {
      screens: {
        SelectOrganisation: "/:sort",
      },
    },
  };

  return (
    <NavigationContainer
      linking={linking}
      fallback={<Text>Loading...</Text>}
      // theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      theme={MyTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator();

function RootNavigator() {
  const [organisationName, setOrganisationName] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      AsyncStorage.getItem("orgName").then((value) => {
        setOrganisationName(value);
      });
    }, 1200);
  });

  return (
    <Provider store={store}>
      <Stack.Navigator
        screenOptions={{ headerShown: false }}
        initialRouteName="SelectOrganisation"
      >
        <Stack.Screen
          name="SelectOrganisation"
          component={SelectOrganisation}
        />
        <Stack.Screen
          name="HomePage"
          component={HomePage}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="Signup"
          component={Signup}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="Login"
          component={Login}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="LoginExternal"
          component={LoginExternal}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="NewCritera"
          component={NewCritera}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="LandingCritera"
          component={LandingCritera}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="Additem"
          component={Additem}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="AddItemCart"
          component={AddItemCart}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="Additemoption"
          component={Additemoption}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="AddItemSearch"
          component={AddItemSearch}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="AddPrice"
          component={AddPrice}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="AddItemProductType"
          component={AddItemProductType}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="CreateAccount"
          component={CreateAccount}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="AddItemSuccess"
          component={AddItemSuccess}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="DropOffItem"
          component={DropOffItem}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="DropOffItemAccept"
          component={DropOffItemAccept}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="ItemSoid"
          component={ItemSoid}
          options={{ title: `${organisationName} | Serculate` }}
        />

        <Stack.Screen
          name="LandingCriteraNotAccept"
          component={LandingCriteraNotAccept}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="LandingCriteraAccept"
          component={LandingCriteraAccept}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="Resale"
          component={Resale}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="DropOffItemLocation"
          component={DropOffItemLocation}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="AddItemCancle"
          component={AddItemCancle}
          options={{ title: `${organisationName} | Serculate` }}
        />

        <Stack.Screen
          name="Collection"
          component={Collection}
          options={{ title: `${organisationName} | Serculate` }}
        />
        {/* darkTheme bag registration screens */}
        <Stack.Screen
          name="BagJournyLandingCriteria"
          component={BagJournyLandingCriteria}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="AddAddress"
          component={AddAddress}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="BookedBag"
          component={BookedBag}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="ClothBagCollection"
          component={ClothBagCollection}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="ItemDetails"
          component={ItemDetails}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="DropOffBagItem"
          component={DropOffBagItem}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="SoldClothBagCollection"
          component={SoldClothBagCollection}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="SoldClothBagCounterOffer"
          component={SoldClothBagCounterOffer}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="SoldClothBagCollectionIntegration"
          component={SoldClothBagCollectionIntegration}
          options={{ title: `${organisationName} | Serculate` }}
        />

        {/* Retailer Pages */}
        <Stack.Screen
          name="RetailerHome"
          component={RetailerHome}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="QRcodeScanner"
          component={QRcodeScanner}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="RetailItemDetails"
          component={RetailItemDetails}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="RetailItemProductType"
          component={RetailItemProductType}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="CommissionWithdraw"
          component={CommissionWithdraw}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="ContactUs"
          component={ContactUs}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="register"
          component={Register}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="TermsCondition"
          component={TermsCondition}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="zimpler_status"
          component={ZimplerStatus}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="ChatScreen"
          component={ChatScreen}
          options={{ title: `${organisationName} | Serculate` }}
        />
        <Stack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{ title: "Oops!" }}
        />
      </Stack.Navigator>
    </Provider>
  );
}
