import React, { useState, useEffect } from "react";
import { View, Platform, SafeAreaView, StatusBar, Image } from "react-native";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector, useDispatch } from "react-redux";
import Barcode from "react-barcode";
import styles from "../components/style/styles";
import {
  getProductDetails,
  getOrganisationById,
  selfServiceFlowUpdate,
  myDetails,
} from "../actions";
import {
  setProduct,
  setCustomer,
} from "../components/redux/actions/prodActions";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import { setAuthHeader } from "../axiosApi";
import CoreBagCollectionHeader from "../components/core/CoreBagCollectionHeader";
import CoreLoader from "../components/core/CoreLoader";
import { Status, ReadyToSaleStatus } from "../sharedLib/sourcingItemStatus.js";
import Header from "../components/core/Header";
import Colors from "../constants/Colors";
import CoreFooter from "../components/core/CoreFooter";
import Button from "../components/Button";
import { useReactToPrint } from "react-to-print";
import MontserratRegular from "../assets/Font/Montserrat-Regular.ttf";
import moment from "moment";

const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");

let _user;
let orgName;
const SoldClothBagCollection = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const [soldItems, setSoldItems] = useState([]);
  const [pendingItems, setPendingItems] = useState([]);
  const [actionRequriedItems, setActionRequriedItems] = useState([]);
  const [loadItem, setLoadItem] = useState(true);
  const dispatch = useDispatch();
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgLaunguage = useSelector((state) => state.prodReducer.languageData);
  const selfServiceFlow = orgDetail?.[0]?.selfServiceFlow;
  const [sku, setSku] = useState("");
  const [prodDetails, setProdDetails] = useState("");

  const [customerDetail, setCustomerDetail] = useState({});
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const barcodeData = orgSetting?.barcode_setting?.split(",");

  const handleGetItem = async () => {
    await myDetails(orgName)
      .then((resp) => {
        setCustomerDetail(resp?.data[0]);
      })
      .catch((error) => { });
    await getProductDetails(orgName)
      .then(({ data }) => {
        const tmpSold = [];
        const tmpPending = [];
        const tmpActionRequried = [];
        data?.forEach((element) => {
          // If the item is sold
          if (element.sale_status == ReadyToSaleStatus.Sold) {
            element.label =
              "Plagget är sålt, din komission är " +
              (element.commission * element.price) / 100 +
              " kr";
            tmpSold.push(element);
          } else {
            // The item is ready to be sold
            if (element.sale_status == ReadyToSaleStatus.IsReadyToBeSold) {
              element.label =
                "Plagget är ute till försäljning för " + element.price + " kr";
              tmpPending.push(element);
            } else {
              // The admin is suppose to mark it as accepted
              if (element.status == Status.Pending) {
                element.label = "Butiken överväger plagget";
                tmpActionRequried.push(element);
              }
              // Some action is requried, the user needs to send it to the merchent
              if (element.status == Status.Accepted) {
                element.label = "Skicka plagget till butiken";
                element.type = "transport";
                tmpActionRequried.push(element);
              }
              // Some action is requried, the user has an counter offer to either accept or reject
              if (element.status == Status.CounterOffer) {
                element.label = "Förslag ifrån butiken";
                element.type = "counteroffer";
                tmpActionRequried.push(element);
              }
            }
          }
        });

        setSoldItems(tmpSold);
        setPendingItems(tmpPending);
        setActionRequriedItems(tmpActionRequried);

        setLoadItem(false);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    navigation.addListener("focus", async () => {
      setLoadItem(true);
      if (orgName) {
        handleGetItem();
      }
    });
    return () => {
      console.log("======nativeUnmount");
    };
  }, [navigation]);

  useEffect(async () => {
    orgName = await AsyncStorage.getItem("organisation");
    setLoadItem(true);
    const _token = await AsyncStorage.getItem("token");
    setAuthHeader(_token);
    const getUser = await AsyncStorage.getItem("customer");
    _user = JSON.parse(getUser);
    dispatch(setCustomer(_user));
    await getOrganisationById(pickOrg)
      .then((resp) => {
        dispatch(setProduct(resp?.data));
        setIsLoad(false);
      })
      .catch((error) => { });
    handleGetItem();
  }, []);

  const comission = "0";

  const totalComission = soldItems
    ?.filter((item) => item.sale_status === ReadyToSaleStatus.Sold)
    ?.reduce((sum, item) => item.sale_status && sum + item.commission, 0);

  const printRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleClick = async (_prodDetails) => {
    setProdDetails(_prodDetails);
    setSku(_prodDetails.sku);
    // const orgName = await AsyncStorage.getItem("organisation")
    // const data = {
    //   ..._prodDetails,
    //   sku: sku
    // }
    // await selfServiceFlowUpdate(data, orgName)
    setTimeout(() => {
      handlePrint();
    }, 500);
  };

  function handleActionRequired(item) {
    if (item.status === Status.CounterOffer) {
      navigation.navigate("SoldClothBagCounterOffer", {
        item: item.id,
        totalItem: soldItems?.length,
        organisationId: pickOrg,
        allComission: totalComission,
      });
    }

    if (item.status === Status.Accepted) {
      navigation.navigate("DropOffItem", {
        item: item.id,
        totalItem: soldItems?.length,
        organisationId: pickOrg,
        allComission: totalComission,
      });
    }
  }

  const brand = (
    <CoreText
      textTitle={`varumärke: ${prodDetails?.brand || '--'}`}
      fontType={MontserratRegular}
    />
  );
  const category = (
    <CoreText
      textTitle={`Kategori: ${prodDetails?.category || '--'}`}
      fontType={MontserratRegular}
    />
  );
  const vat = (
    <CoreText
      textTitle={`VAT: ${prodDetails?.vat || '--'}`}
      fontType={MontserratRegular}
    />
  );
  const description = (
    <CoreText
      textTitle={`Beskrivning: ${prodDetails?.beskrivning || '--'}`}
      fontType={MontserratRegular}
    />
  );
  const allFields = {
    Brand: brand,
    Category: category,
    Vat: vat
  };
  const selectedOrgBarcode = barcodeData?.map((item, index) => {
    return <View key={index}>{allFields[item]}</View>;
  });

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      {loadItem ? (
        <CoreLoader
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        <>
          <Header navigation={navigation} route={route} isHomePage isHeader />

          <ScrollView>
            <View style={styles.coreBagHeader}>
              <CoreBagCollectionHeader
                totalItem={soldItems?.length}
                comission={totalComission || comission}
                isHeaderImage
              />
            </View>
            <View style={[styles.availableProduct]}>
              <CoreText
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                }}
                textTitle={
                  orgLaunguage?.soldcollection_title || "Mina inlämmningar"
                }
                fontType={MontserratLight}
              />
              {actionRequriedItems.length != 0 ? (
                <View style={{ marginVertical: 20 }}>
                  <CoreText
                    style={[styles.clothItemText, { fontSize: 16 }]}
                    textTitle={
                      orgLaunguage?.soldcollection_subtitle1 ||
                      "Inskickat för godkännande"
                    }
                    fontType={MontserratBold}
                  />
                </View>
              ) : (
                <View />
              )}
              {/* Below are items that are items that require action  */}
              {actionRequriedItems?.map((item, index, array) => (
                <View
                  key={index}
                  style={{
                    backgroundColor: "#F9F6F1",
                    borderRadius: 20,
                    marginBottom: 10,
                    paddingVertical: 10,
                    paddingHorizontal: 15,
                    flexDirection: "row",
                  }}
                >
                  {item?.photo?.[0]?.url && (
                    <View style={{ width: 100 }}>
                      <Image
                        style={[styles.productImage, { marginRight: 20 }]}
                        source={{ uri: item?.photo?.[0]?.url || null }}
                      />
                    </View>
                  )}
                  <View
                    style={{
                      flexDirection: "column",
                      width: 160,
                      marginLeft: 10,
                    }}
                  >
                    <View>
                      <CoreText
                        style={[
                          styles.productDetails,
                          { fontSize: 14, marginBottom: 0 },
                        ]}
                        textTitle={item.brand || item.description}
                        fontType={MontserratBold}
                      />
                    </View>
                    {!selfServiceFlow && (item?.gender || item?.category) && (
                      <View>
                        <CoreText
                          dropOffItem
                          FirstTitle={item?.gender}
                          lastTitle={item?.category}
                          icon={
                            item?.gender &&
                            item?.category && (
                              <AntDesign
                                name="right"
                                size={9}
                                color="rgba(15, 15, 15, 0.4)"
                              />
                            )
                          }
                          style={[
                            styles.headerTitle,
                            { color: "rgba(0, 0, 0, 0.48)", fontSize: 12 },
                          ]}
                          fontType={MontserratLight}
                        />
                      </View>
                    )}
                    {selfServiceFlow && (
                      <CoreText
                        style={[
                          styles.productDetails,
                          { fontSize: 12, marginBottom: 0 },
                        ]}
                        textTitle={`SKU : ${item.sku ? item.sku : `3-${index + 1}`
                          }`}
                        fontType={MontserratBold}
                      />
                    )}
                    {selfServiceFlow ? (
                      <View
                        style={[
                          {
                            marginLeft: 10,
                          },
                        ]}
                      >
                        <Button
                          style={{
                            backgroundColor: "black",
                            maxHeight: 33,
                            borderRadius: 5,
                          }}
                          btnTitle={"Save and Print"}
                          onPress={() =>
                            handleClick({
                              ...item,
                              sku: item.sku || `3-${index + 1}`,
                            })
                          }
                          textstyle={[{ lineHeight: 10, fontSize: 12 }]}
                        />
                      </View>
                    ) : (
                      <View
                        style={[
                          styles.consideredByStore,
                          {
                            marginTop: 4,
                            backgroundColor: Colors.WHITE,
                            borderRadius: 8,
                          },
                        ]}
                      >
                        <CoreText
                          style={styles.storeText}
                          textTitle={item.label}
                          fontType={MontserratBold}
                        />
                      </View>
                    )}
                    <CoreText
                      style={[{
                        marginTop: 5,
                        marginLeft: 5
                      }, styles.storeText]}
                      textTitle={`Created date: ${moment(item?.createdat).format("YYYY/MM/DD")}`
                      }
                      fontType={MontserratBold}
                    />
                  </View>
                  <View
                    style={{
                      justifyContent: "center",
                      width: 40,
                      alignItems: "center",
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        handleActionRequired(item);
                      }}
                      style={{ flexDirection: "row" }}
                    >
                      <MaterialIcons
                        name="keyboard-arrow-right"
                        size={30}
                        color="black"
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              ))}
            </View>

            {/* Below are items that are ready to be sold */}
            <View style={[styles.availableProduct, { marginBottom: 30 }]}>
              <View style={{ marginVertical: 20 }}>
                <CoreText
                  style={[styles.clothItemText, { fontSize: 16 }]}
                  textTitle={
                    orgLaunguage?.soldcollection_subtitle2 || "Till försäljning"
                  }
                  fontType={MontserratBold}
                />
              </View>
              {pendingItems?.map((item, index, array) => (
                <View
                  key={index}
                  style={{
                    backgroundColor: "#F9F6F1",
                    borderRadius: 20,
                    marginBottom: 10,
                    paddingVertical: 10,
                    paddingHorizontal: 15,
                    flexDirection: "row",
                  }}
                >
                  <TouchableOpacity style={{ flexDirection: "row" }}>
                    {item?.photo?.[0]?.url && (
                      <View style={{ width: 100 }}>
                        <Image
                          style={[styles.productImage, { marginRight: 20 }]}
                          source={{ uri: item?.photo?.[0]?.url || null }}
                        />
                      </View>
                    )}
                    <View
                      style={{
                        flexDirection: "column",
                        width: 160,
                        marginLeft: 10,
                      }}
                    >
                      <View>
                        <CoreText
                          style={[
                            styles.productDetails,
                            { fontSize: 14, marginBottom: 0 },
                          ]}
                          textTitle={item.brand || item.description}
                          fontType={MontserratBold}
                        />
                      </View>
                      {(item?.gender || item?.category) && (
                        <View>
                          <CoreText
                            dropOffItem
                            FirstTitle={item?.gender}
                            lastTitle={item?.category}
                            icon={
                              item?.gender &&
                              item?.category && (
                                <AntDesign
                                  name="right"
                                  size={9}
                                  color="rgba(15, 15, 15, 0.4)"
                                />
                              )
                            }
                            style={[
                              styles.headerTitle,
                              { color: "rgba(0, 0, 0, 0.48)", fontSize: 12 },
                            ]}
                            fontType={MontserratLight}
                          />
                        </View>
                      )}
                      <View
                        style={[
                          styles.consideredByStore,
                          {
                            marginTop: 4,
                            backgroundColor: Colors.WHITE,
                            borderRadius: 8,
                          },
                        ]}
                      >
                        <CoreText
                          style={styles.storeText}
                          textTitle={`Försäljningspris i butik ${item.price || 0
                            } SEK`}
                          fontType={MontserratBold}
                        />
                      </View>
                      <View
                        style={[
                          styles.consideredByStore,
                          {
                            marginTop: 4,
                            backgroundColor: Colors.WHITE,
                            borderRadius: 8,
                          },
                        ]}
                      >
                        <CoreText
                          style={styles.storeText}
                          textTitle={`Beräknade komission vid försäljning är ${item.price * (item.commission / 100) || 0
                            } SEK`}
                          fontType={MontserratBold}
                        />
                      </View>
                      <CoreText
                        style={[{
                          marginTop: 5,
                          marginLeft: 5
                        }, styles.storeText]}
                        textTitle={`Created date: ${moment(item?.createdat).format("YYYY/MM/DD")}`
                        }
                        fontType={MontserratBold}
                      />
                    </View>
                    <View
                      style={{
                        justifyContent: "center",
                        width: 40,
                        alignItems: "center",
                      }}
                    >
                      <MaterialIcons
                        name="keyboard-arrow-right"
                        size={30}
                        color="black"
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              ))}
            </View>

            {/* Below are items that are sold */}
            <View style={[styles.availableProduct, { marginBottom: 30 }]}>
              <View style={{ marginVertical: 20 }}>
                <CoreText
                  style={[styles.clothItemText, { fontSize: 16 }]}
                  textTitle={
                    orgLaunguage?.soldcollection_subtitle3 || "Sålda varor"
                  }
                  fontType={MontserratBold}
                />
              </View>
              {soldItems?.map((item, index, array) => (
                <View
                  key={index}
                  style={{
                    backgroundColor: "#F9F6F1",
                    borderRadius: 20,
                    marginBottom: 10,
                    paddingVertical: 10,
                    paddingHorizontal: 15,
                    flexDirection: "row",
                  }}
                >
                  {item?.photo?.[0]?.url && (
                    <View style={{ width: 100 }}>
                      <Image
                        style={[styles.productImage, { marginRight: 20 }]}
                        source={{ uri: item?.photo?.[0]?.url || null }}
                      />
                    </View>
                  )}
                  <View
                    style={{
                      flexDirection: "column",
                      width: 160,
                      marginLeft: 10,
                    }}
                  >
                    <View>
                      <CoreText
                        style={[
                          styles.productDetails,
                          { fontSize: 14, marginBottom: 0 },
                        ]}
                        textTitle={item?.brand || item?.description}
                        fontType={MontserratBold}
                      />
                    </View>
                    {(item?.gender || item?.category) && (
                      <View>
                        <CoreText
                          dropOffItem
                          FirstTitle={item?.gender}
                          lastTitle={item?.category}
                          icon={
                            item?.gender &&
                            item?.category && (
                              <AntDesign
                                name="right"
                                size={9}
                                color="rgba(15, 15, 15, 0.4)"
                              />
                            )
                          }
                          style={[
                            styles.headerTitle,
                            { color: "rgba(0, 0, 0, 0.48)", fontSize: 12 },
                          ]}
                          fontType={MontserratLight}
                        />
                      </View>
                    )}
                    <View
                      style={[
                        styles.consideredByStore,
                        {
                          marginTop: 4,
                          backgroundColor: Colors.WHITE,
                          borderRadius: 8,
                        },
                      ]}
                    >
                      <CoreText
                        style={styles.storeText}
                        textTitle={`Din komission är ${item.price * (item.commission / 100) || 0
                          } SEK`}
                        fontType={MontserratBold}
                      />
                    </View>
                    <CoreText
                      style={[{
                        marginTop: 5,
                        marginLeft: 5
                      }, styles.storeText]}
                      textTitle={`Created date: ${moment(item?.createdat).format("YYYY/MM/DD")}`
                      }
                      fontType={MontserratBold}
                    />
                  </View>
                </View>
              ))}
            </View>
          </ScrollView>
          <CoreFooter />
        </>
      )}
      {selfServiceFlow && (
        <View
          style={{
            marginTop: 15,
            marginHorizontal: 20,
            display: sku ? "block" : "none",
          }}
          ref={printRef}
        >
          <Barcode value={sku} textAlign="left" fontSize="18px" />
          {prodDetails?.price > 0 && (
            <View style={{ textAlign: "left", marginTop: -5 }}>
              <View
                style={{
                  fontSize: 17,
                  color: "#000000",
                  fontFamily: "monospace",
                  paddingLeft: 12,
                  letterSpacing: 10,
                }}
              >
                <CoreText
                  textTitle={`Pris: ${prodDetails?.price} kr`}
                  fontType={MontserratRegular}
                />
              </View>
              {orgSetting?.locationInStore && (
                <View
                  style={{
                    fontSize: 17,
                    color: "#000000",
                    fontFamily: "monospace",
                    paddingLeft: 12,
                    letterSpacing: 10,
                  }}
                >
                  <CoreText
                    textTitle={`Plats i butik: ${customerDetail?.locationInStore}`}
                    fontType={MontserratRegular}
                  />
                </View>
              )}
              <View
                style={{
                  fontSize: 17,
                  color: "#000000",
                  fontFamily: "monospace",
                  paddingLeft: 12,
                  letterSpacing: 10,
                }}
              >
                {selectedOrgBarcode}
              </View>
            </View>
          )}
        </View>
      )}
    </SafeAreaView>
  );
};
export default SoldClothBagCollection;
