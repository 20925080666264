import React, {useState} from "react";
import {
  View,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
} from "react-native";
import {Card, TextInput, TouchableRipple} from "react-native-paper";
import styles from "../components/style/styles";
import CoreText from "../components/core/CoreText";
import { useSelector } from "react-redux";
import Header from "../components/core/Header";
import Colors from "../constants/Colors";
import CoreFooter from "../components/core/CoreFooter";
import MontserratLight from "../assets/Font/Montserrat-Light.ttf";
import MontserratRegular from "../assets/Font/Montserrat-Regular.ttf";
import Button from "../components/Button";

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const AddPrice = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const gender = route.params?.gender;
  const clothType = route.params?.clothType;
  const brandType = route.params?.brandType;
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgLaunguage = useSelector((state) => state.prodReducer.languageData);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const priceRange = orgDetail?.[0]?.priceRange;
  const selfServiceFlow = orgDetail?.[0]?.selfServiceFlow;

  const handleClick = () => {
    navigation.navigate("AddItemProductType", {
      gender,
      clothType,
      brandType,
      priceInterval: priceRange[0].range,
      organisationId: pickOrg,
      description: description,
      price: price,
    })
  }
  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <Header
          navigation={navigation}
          route={route}
          isHeaderItem
          progress={0.6}
          steps={4}
        />
        <ScrollView>
          <View style={styles.Landingcontainer}>
            <View style={styles.searchBody}>
              <View style={{ paddingHorizontal: 20 }}>
                <CoreText
                  textTitle={
                    orgLaunguage?.AddPrice_title ||
                    "Ungefär vad var nypris för plagget"
                  }
                  style={[
                    styles.Title,
                    {
                      color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                      textAlign: "center",
                    },
                  ]}
                  fontType={MontserratMedium}
                />
              </View>
            </View>
            {selfServiceFlow ?
              <View style={{ paddingHorizontal: 20 }}>
                <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                  <CoreText
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                    textTitle="description"
                    fontType={MontserratLight}
                  />
                  <TextInput
                    label={
                      <CoreText
                        textTitle="Please fill in a description of your item"
                        style={[styles.input]}
                        fontType={MontserratRegular}
                        isInput
                      />
                    }
                    style={[styles.inputFiledstyle]}
                    value={description}
                    keyboardType="default"
                    placeholderTextColor="#878787"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </View>
                <View style={{ marginTop: 15, marginHorizontal: 20 }}>
                  <CoreText
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                    textTitle="Price"
                    fontType={MontserratLight}
                  />
                  <TextInput
                    label={
                      <CoreText
                        textTitle="Please fill in the price"
                        style={[styles.input]}
                        fontType={MontserratRegular}
                        isInput
                      />
                    }
                    style={[styles.inputFiledstyle]}
                    value={price}
                    keyboardType="default"
                    placeholderTextColor="#878787"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </View>
                <View>
                  <View
                    style={[
                      styles.btnSection,
                      styles.footerButtonSection,
                      { marginBottom: 30 },
                    ]}
                  >
                    <Button
                      btnTitle={'Fortsätt'}
                      disabled={!price}
                      disableButtondata={!price}
                      onPress={handleClick}
                      textstyle={
                        price ? styles.btntext : styles.disableBtnStyle
                      }
                    />
                  </View>
                </View>
              </View>
              :
            <View style={[styles.searchmainContent, { marginBottom: 30 }]}>
              <View>
                {priceRange?.map((item, index) => (
                  <View key={index}>
                    <View style={{ marginBottom: 6 }}>
                      <Card
                        style={{ borderRadius: 12, shadowColor: "transparent" }}
                      >
                        <TouchableRipple
                          style={[styles.cardStyle, { paddingVertical: 16 }]}
                          onPress={() =>
                            navigation.navigate("AddItemProductType", {
                              gender,
                              clothType,
                              brandType,
                              priceInterval: item.range,
                              organisationId: pickOrg,
                            })
                          }
                          rippleColor="rgba(0, 0, 0, .32)"
                        >
                          <CoreText
                            textTitle={`${item.range} kr`}
                            style={styles.headerTitle}
                            fontType={MontserratBold}
                          />
                        </TouchableRipple>
                      </Card>
                    </View>
                  </View>
                ))}
              </View>
            </View>
            }
          </View>
        </ScrollView>
        <CoreFooter />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
export default AddPrice;
