import React, { useState, useEffect } from "react";
import { View, Platform, SafeAreaView, StatusBar } from "react-native";
import { useSelector } from "react-redux";
import styles from "../components/style/styles";
import {
  getArticlesByCustomerId,
  getComission,
  getCustomerDetails,
  adjustComission,
} from "../actions";
import CoreText from "../components/core/CoreText";
import { TextInput } from "react-native-paper";
import Button from "../components/Button";
import { ScrollView } from "react-native-gesture-handler";

import CoreBagCollectionHeader from "../components/core/CoreBagCollectionHeader";
import CoreLoader from "../components/core/CoreLoader";
import Header from "../components/core/Header";
import CoreFooter from "../components/core/CoreFooter";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");

const ContactUs = ({ navigation, route }) => {
  const customers = useSelector((state) => state.prodReducer.customerDetail);
  const totalItem = route.params.totalItem;
  const allComission = route.params.allComission;
  const orgDetail = useSelector((state) => state.prodReducer.products);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <Header navigation={navigation} route={route} isHomePage isHeader />
      <ScrollView>
        {customers?.isLogin ? (
          <View>
            <View style={styles.coreBagHeader}>
              <CoreBagCollectionHeader
                totalItem={totalItem}
                comission={allComission}
                isHeaderImage
              />
            </View>
          </View>
        ) : (
          <View />
        )}

        <View style={styles.availableProduct}>
          <CoreText
            textTitle={`Har du frågar så kan du alltid kontakta oss på ${orgDetail?.[0]?.email || customers?.email} och skriva ditt ärende. Du kan även vända dig till butiken för frågar.`}
            style={[
              styles.headerSubTitle,
              {
                paddingTop: 10,
                textAlign: "center",
                display: "flex",
                overflow: "visible",
              },
            ]}
            fontType={MontserratMedium}
          />
        </View>
      </ScrollView>
      <CoreFooter />
    </SafeAreaView>
  );
};
export default ContactUs;
