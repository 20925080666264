import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
  Text,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { linkItemWithUser, createCustomer } from "../actions";
import styles from "../components/style/styles.js";
import { TextInput, Checkbox } from "react-native-paper";
import Colors from "../constants/Colors";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import { setAuthHeader } from "../axiosApi";
import Header from "../components/core/Header";
import { setCustomer } from "../components/redux/actions/prodActions";
import CoreFooter from "../components/core/CoreFooter";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");

const CreateAccount = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const getItem = route.params.sourcedItemId;

  const cartItem = route.params.cartItem;
  const textValue = route.params.textValue;
  const customers = useSelector((state) => state.prodReducer.customerDetail);
  const userField =
    customers?.phoneNumber?.replace(
      /^(.{3})(.{3})(.{2})(.{2})$/,
      "$1 $2 $3 $4"
    ) || customers?.email;
  const [text, setText] = useState(userField);
  const [isError, setIsError] = useState(false);
  const [isLoadCustomer, setIsLoadCustomer] = useState(false);
  const [username, setUserName] = useState("");
  const [erroMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [nextBtnError, setNextBtnError] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [locationInStore, setLocationInStore] = useState('');
  const [checked, setChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const re = /^[0-9\b]+$/;
  const reg =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\+[0-9]{12})+$/;

  const poolData = useSelector((state) => state.prodReducer.userPool);
  const userPoolData = poolData;
  const dispatch = useDispatch();
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const termsItem = orgDetail?.[0]?.terms_conditions;

  const onChange = (value) => {
    setNextBtnError("");
    if (!value) {
      setIsError(true);
      setText("");
    } else {
      setIsError(false);
      if (re.test(value)) {
        const numberFormat = value.replace(
          /^(.{3})(.{3})(.{2})(.{2})$/,
          "$1 $2 $3 $4"
        );
        setText(numberFormat);
      } else {
        setText(value);
      }
    }
  };

  useEffect(async () => {
    if (text === "") {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, []);

  const handleNext = async (email, password) => {
    setIsLoadCustomer(true);
    if (email === "") {
      setIsError(true);
      setErrorMsg("Ange en epost address");
      setIsLoadCustomer(false);
    } else if (username === "") {
      setIsError(true);
      setErrorMsg("Vänligen ange fullständigt namn");
      setIsLoadCustomer(false);
    } else if (!reg.test(email.split(" ").join(""))) {
      setIsError(true);
      setErrorMsg("Ange en epost address");
      setIsLoadCustomer(false);
    } else {
      setErrorMsg("");
      const attriList = [];
      const authenticationData = {
        Username: email,
        Password: password,
      };
      const EmailAttributes = {
        Name: "email",
        Value: email,
      };
      const nameAttributes = {
        Name: "name",
        Value: username,
      };
      attriList?.push(new CognitoUserAttribute(EmailAttributes));
      attriList?.push(new CognitoUserAttribute(nameAttributes));
      poolData.signUp(email, password, attriList, null, (err, data) => {
        if (err) {
          setIsError(true);

          setNextBtnError("Något gick fel, prova att ändra dina uppgifter");

          if (err.code == "UsernameExistsException") {
            setNextBtnError("Eposten används redan, använd en annan");
          }

          if (err.code == "InvalidParameterException") {
            setNextBtnError(
              "Eposten har felaktigt format, kontrollera att det inte är något mellanrum eller liknande i epost addressen"
            );
          }

          if (err.code == "InvalidPasswordException") {
            setNextBtnError("Använd minst 6 tecken i ditt lösenord");
          }

          setIsLoadCustomer(false);
        } else {
          setIsError(false);
          setNextBtnError("message");
          const authDetails = new AuthenticationDetails(authenticationData);
          const userData = {
            Username: email,
            Pool: userPoolData,
          };

          const congnitoUser = new CognitoUser(userData);
          congnitoUser.authenticateUser(authDetails, {
            onSuccess(result = CognitoUserSession) {
              if (result) {
                setAuthHeader(result?.idToken?.jwtToken);
                AsyncStorage.setItem("user", JSON.stringify({ ...result }));
                AsyncStorage.setItem("token", result?.idToken?.jwtToken);
                const getUserInfo = result?.idToken?.payload;

                const cutomerData = {
                  email: getUserInfo?.email,
                  locationInStore: locationInStore,
                  phoneNumber: getUserInfo?.phone_number,
                  id: getUserInfo?.sub,
                  isLogin: true,
                };
                dispatch(setCustomer(cutomerData));
                AsyncStorage.setItem("customer", JSON.stringify(cutomerData));
                const data = {
                  email: getUserInfo?.email,
                  organisationId: pickOrg,
                  id: getUserInfo?.sub,
                  name: username,
                  locationInStore: locationInStore,
                };
                createCustomer(data);
                setTimeout(() => {
                  if (getItem) {
                    linkItemWithUser(getItem);
                    navigation.navigate("AddItemSuccess", {
                      organisationId: pickOrg,
                    });
                  } else {
                    navigation.navigate("SoldClothBagCollection", {
                      organisationId: pickOrg,
                    });
                  }
                }, 1000);
              }
            },
            onFailure(err) {
              const { message } = err;
              setErrorMsg(message);
              setIsLoadCustomer(false);
            },
          });

          navigation.navigate("AddItemSuccess", {
            cartItem,
            textValue,
            organisationId: pickOrg,
          });
          setIsLoadCustomer(false);
        }
      });
    }
  };

  // submit data whe we press enter
  const handleKeyPress = (keyName) => {
    if (keyName === "Enter") {
      handleNext(text, password);
    }
  };

  const windowFeatures =
    "toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=200,width=550,height=500";

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <Header navigation={navigation} route={route} isHeader />
        <ScrollView>
          <View style={[styles.Landingcontainer]}>
            <View style={[styles.alignItems, styles.justifyContent]}>
              <View style={{ paddingHorizontal: 20 }}>
                <CoreText
                  textTitle="Plagget är inskickat!"
                  style={[
                    styles.mainTitle,
                    {
                      paddingTop: 36,
                      fontSize: 24,
                      paddingBottom: 16,
                      textAlign: "center",
                    },
                  ]}
                  fontType={MontserratMedium}
                />
                <CoreText
                  textTitle="Skapa ett konto så vi kan kontakta dig strax när vi tittat på plagget"
                  style={[
                    styles.subTitle,
                    { textAlign: "center", fontSize: 14 },
                  ]}
                  fontType={MontserratExtraLight}
                />
              </View>
            </View>
          </View>
          <View style={[styles.btnsection, { marginTop: 16 }]}>
            <View style={{ marginBottom: 18 }}>
              <TextInput
                label={
                  <CoreText
                    textTitle="Ditt namn"
                    style={[styles.input]}
                    fontType={MontserratRegular}
                    isInput
                  />
                }
                autoComplete="off"
                autoCompleteType="off"
                style={[styles.inputFiledstyle]}
                value={username}
                keyboardType="default"
                placeholderTextColor="#878787"
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                onChangeText={(text) => {
                  setIsError(false);
                  setNextBtnError("");
                  setUserName(text);
                }}
                theme={{ colors: { text: Colors.BLACK } }}
              />
            </View>
            <View style={{ marginBottom: 18 }}>
              <TextInput
                label={
                  <CoreText
                    textTitle="Epost"
                    style={[styles.input]}
                    fontType={MontserratRegular}
                    isInput
                  />
                }
                autoComplete="off"
                autoCompleteType="off"
                style={[styles.inputFiledstyle]}
                value={text}
                keyboardType="default"
                placeholderTextColor="#878787"
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                onChangeText={(text) => {
                  setIsError(false);
                  setNextBtnError("");
                  onChange(text);
                }}
                theme={{ colors: { text: Colors.BLACK } }}
              />
              {isError && (
                <CoreText
                  textTitle={isError && erroMsg}
                  style={[isError && styles.error]}
                  fontType={MontserratRegular}
                />
              )}
            </View>

            <View>
              <TextInput
                label={
                  <CoreText
                    textTitle="Lösenord"
                    style={[styles.input]}
                    fontType={MontserratRegular}
                    isInput
                  />
                }
                autoComplete="off"
                autoCompleteType="off"
                style={[styles.inputFiledstyle, { marginBottom: 10 }]}
                value={password}
                secureTextEntry={showPassword}
                right={
                  <TextInput.Icon
                    name={showPassword ? "eye-off" : "eye"}
                    onPress={() => setShowPassword(!showPassword)}
                    theme={{ colors: { text: Colors.BLACK } }}
                  />
                }
                maxLength={50}
                keyboardType="default"
                placeholderTextColor="#878787"
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                onChangeText={(e) => {
                  setIsError(false);
                  setNextBtnError("");
                  setPassword(e);
                }}
                onKeyPress={(e) => handleKeyPress(e.key)}
                theme={{ colors: { text: Colors.BLACK } }}
              />
              {isError && (
                <CoreText
                  textTitle={isError && nextBtnError}
                  style={[isError && styles.error]}
                  fontType={MontserratRegular}
                />
              )}
            </View>
            {orgSetting?.locationInStore && (
              <View>
                <TextInput
                  label={
                    <CoreText
                      textTitle="Plats i butik"
                      style={[styles.input]}
                      fontType={MontserratRegular}
                      isInput
                    />
                  }
                  autoComplete="off"
                  autoCompleteType="off"
                  style={[styles.inputFiledstyle, { marginBottom: 10 }]}
                  value={locationInStore}
                  maxLength={50}
                  keyboardType="default"
                  placeholderTextColor="#878787"
                  underlineColor="transparent"
                  activeUnderlineColor="transparent"
                  onChangeText={(e) => setLocationInStore(e)}
                  onKeyPress={(e) => handleKeyPress(e.key)}
                  theme={{ colors: { text: Colors.BLACK } }}
                />
              </View>
            )}
            <View style={{ flexDirection: "row" }}>
              <Checkbox
                status={checked ? "checked" : "unchecked"}
                onPress={() => {
                  setChecked(!checked);
                }}
                color="#EDEDED"
                theme={{ colors: { text: Colors.BLACK } }}
              />{" "}
              <CoreText
                textTitle={
                  <>
                    Jag accepterar Serculate's {""}
                    <Text
                      style={styles.createAcoontText}
                      onPress={() => {
                        window.open("https://www.serculate.com/tc", "_blank");
                      }}
                    >
                      användarvillkor
                    </Text>{" "}
                    och {""}
                    <Text
                      style={styles.createAcoontText}
                      onPress={() => {
                        window.open("https://www.serculate.com/gdrp", "_blank");
                      }}
                    >
                      integritetspolicy
                    </Text>
                  </>
                }
                style={[styles.subTitle, { fontSize: 12, paddingTop: 6 }]}
                fontType={MontserratRegular}
              />
            </View>
            {termsItem && (
              <View style={{ flexDirection: "row" }}>
                <Checkbox
                  status={termsChecked ? "checked" : "unchecked"}
                  onPress={() => {
                    setTermsChecked(!termsChecked);
                  }}
                  color="#EDEDED"
                  theme={{ colors: { text: Colors.BLACK } }}
                />
                <CoreText
                  textTitle={
                    <>
                      I accept
                      <Text
                        style={styles.createAcoontText}
                        onPress={() => {
                          window.open(
                            `/TermsCondition?organisationId=${pickOrg}`,
                            "_blank"
                          );
                        }}
                      >
                        {""} {pickOrg}
                      </Text>
                      's Terms
                    </>
                  }
                  style={[styles.subTitle, { fontSize: 12, paddingTop: 6 }]}
                  fontType={MontserratRegular}
                />
              </View>
            )}
          </View>
        </ScrollView>
        <Button
          style={[
            styles.footerButtonSection,
            {
              marginHorizontal: 20,
              marginBottom: 30,
            },
          ]}
          isLoad={isLoadCustomer}
          btnTitle="Fortsätt"
          isError={isError}
          // issinup
          disableButtondata={
            (termsItem && !termsChecked) ||
            !checked ||
            isError ||
            nextBtnError ||
            !text
          }
          disabled={
            (termsItem && !termsChecked) ||
            !checked ||
            isError ||
            nextBtnError ||
            !text
          }
          textstyle={[
            !isError ? styles.btntextstyle : styles.disableBtnStyle,
            { lineHeight: 36 },
          ]}
          onPress={() => handleNext(text, password)}
        />
        <CoreFooter />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
export default CreateAccount;
