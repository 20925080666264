import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  Image,
  Text,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import styles from "../components/style/styles";
import { editProductDetails, getProductDetailsById1 } from "../actions";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import CoreBagCollectionHeader from "../components/core/CoreBagCollectionHeader";
import CoreLoader from "../components/core/CoreLoader";
import Colors from "../constants/Colors";
import { Status } from "../sharedLib/sourcingItemStatus.js";
import CoreFooter from "../components/core/CoreFooter";

const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");
const B = (props) => (
  <Text style={{ fontWeight: "bold", color: Colors.BLACK }}>
    {props.children}
  </Text>
);
const SoldClothBagCounterOffer = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const pickItem = route.params.item;
  const totalItem = route.params.totalItem;
  const allComission = route.params.allComission;
  const [isOfferLoad, setIsOfferLoad] = useState(false);
  const [priceData, setPriceData] = useState();

  useEffect(async () => {
    setIsOfferLoad(true);
    await getProductDetailsById1(pickItem).then(({ data }) => {
      setPriceData(data);
      setIsOfferLoad(false);
    });
  }, []);

  const comission = "0";

  const handleCounterOfferPrice = async (item, status) => {
    setIsOfferLoad(true);
    const data = {
      ...item,
      id: undefined,
      SK: undefined,
      status,
      offer_price: status === Status.AcceptedByCustomer ? item?.offer_price : "",
      counter_offer_reason:
        status === Status.AcceptedByCustomer ? item?.counter_offer_reason : "",
    };
    await editProductDetails(item?.id, data);
    await getProductDetailsById1(pickItem).then(({ data }) => {
      setPriceData(data);
      setIsOfferLoad(false);
    });
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <CoreBagCollectionHeader
        totalItem={totalItem}
        comission={allComission || comission}
      />
      <ScrollView>
        <View style={styles.availableProduct}>
          <View style={{ marginVertical: 30 }}>
            <CoreText
              style={styles.clothItemText}
              textTitle="Förslag"
              fontType={MontserratBold}
            />
          </View>
          {isOfferLoad ? (
            <CoreLoader
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          ) : (
            <>
              <View style={styles.productDetailSection}>
                <TouchableOpacity>
                  <Image
                    style={styles.productImage}
                    source={{ uri: priceData?.photo?.[0]?.url }}
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: "column" }}>
                  <View>
                    <CoreText
                      style={styles.productDetails}
                      textTitle={priceData?.brand}
                      fontType={MontserratBold}
                    />
                  </View>
                  <View>
                    <CoreText
                      dropOffItem
                      FirstTitle={priceData?.gender}
                      lastTitle={priceData?.category}
                      icon={
                        <AntDesign
                          name="right"
                          size={9}
                          color="rgba(15, 15, 15, 0.4)"
                        />
                      }
                      style={[
                        styles.headerTitle,
                        { color: "rgba(0, 0, 0, 0.48)" },
                      ]}
                      fontType={MontserratLight}
                    />
                  </View>
                  <View style={{ width: 70 }}>
                    <TouchableOpacity style={styles.consideredByStore}>
                      <CoreText
                        style={styles.storeText}
                        textTitle="Motbud"
                        fontType={MontserratBold}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={styles.maphorizontalline} />
              <View>
                <CoreText
                  style={
                    ([styles.clothItemText],
                      { fontWeight: "100", color: "#757575" })
                  }
                  textTitle="Du har fått ett förslag"
                  fontType={MontserratLight}
                />
                <CoreText
                  style={
                    ([styles.clothItemText],
                      { fontWeight: "100", color: "#757575" })
                  }
                  textTitle={
                    <>
                      <B style={{ color: Colors.BLACK }}>
                        {priceData?.organisationId}
                      </B>{" "}
                      skriver att vi kan sälja plagget för{" "}
                      <B style={{ color: Colors.BLACK }}>
                        {priceData?.offer_price}{" "}
                      </B>
                      kr
                    </>
                  }
                  fontType={MontserratLight}
                />
              </View>

              {priceData?.status === Status.AcceptedByCustomer ? (
                <View
                  style={[
                    styles.consideredByStore,
                    { width: 225, marginTop: 15, height: 35 },
                  ]}
                >
                  <CoreText
                    style={[styles.headerTitle, { color: Colors.BLACK }]}
                    textTitle="Counter Offer is Accepted"
                    fontType={MontserratBold}
                  />
                </View>
              ) : priceData?.status === Status.RejectedByCustomer ? (
                <View
                  style={[
                    styles.consideredByStore,
                    { width: 225, marginTop: 15, height: 35 },
                  ]}
                >
                  <CoreText
                    style={[styles.headerTitle, { color: Colors.BLACK }]}
                    textTitle="Counter Offer is Rejected"
                    fontType={MontserratBold}
                  />
                </View>
              ) : (
                <>
                  <View style={{ marginTop: 10 }}>
                    <TouchableOpacity
                      style={[
                        styles.consideredByStore,
                        {
                          backgroundColor: Colors.BLACK,
                          height: 48,
                          alignItems: "flex-start",
                        },
                      ]}
                      onPress={() =>
                        handleCounterOfferPrice(
                          priceData,
                          Status.AcceptedByCustomer
                        )
                      }
                    >
                      <CoreText
                        style={[
                          styles.storeText,
                          { color: Colors.WHITE, fontSize: 14 },
                        ]}
                        textTitle={`Acceptera att sålja för ${priceData?.offer_price}`}
                        fontType={MontserratBold}
                      />
                    </TouchableOpacity>
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <TouchableOpacity
                      style={[
                        styles.consideredByStore,
                        {
                          backgroundColor: Colors.BLACK,
                          height: 48,
                          alignItems: "flex-start",
                        },
                      ]}
                      onPress={() =>
                        handleCounterOfferPrice(
                          priceData,
                          Status.RejectedByCustomer
                        )
                      }
                    >
                      <CoreText
                        style={[
                          styles.storeText,
                          { color: Colors.WHITE, fontSize: 14 },
                        ]}
                        textTitle="Avböj"
                        fontType={MontserratBold}
                      />
                    </TouchableOpacity>
                  </View>
                </>
              )}
            </>
          )}
        </View>
      </ScrollView>
      <CoreFooter />
    </SafeAreaView>
  );
};
export default SoldClothBagCounterOffer;
