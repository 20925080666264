import React, { useState, useEffect } from "react";
import { GiftedChat, Bubble, Message, Send } from "react-native-gifted-chat";
import Header from "../components/core/Header";
import { Platform, SafeAreaView, View, StatusBar, Text } from "react-native";
import { useSelector } from "react-redux";
import { getChatMessagesId, addChatMessages } from "../actions";
import styles from "../components/style/styles.js";
import { FontAwesome, MaterialIcons, Entypo } from "@expo/vector-icons";

const ChatScreen = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const [messages, setMessages] = useState([]);
  const customers = useSelector((state) => state.prodReducer.customerDetail);

  const onSend = async (newMessages = []) => {
    try {
      const data = {
        organisation_id: pickOrg,
        sender_id: customers?.id,
        receiver_id: customers?.id,
        message_text: newMessages[0].text,
        attachment: "",
      };

      await addChatMessages(data);
      // setMessages((previousMessages) =>
      //   GiftedChat.append(previousMessages, newMessages)
      // );
      getChatMessage();
    } catch (error) {
      console.error("Error posting message:", error);
    }
  };

  const getChatMessage = async () => {
    const chatMsgData = await getChatMessagesId(pickOrg);
    const formattedMessages = chatMsgData?.data.map((message) => ({
      ...message,
      _id: message?.id.toString(),
      user: {
        _id: message?.sender_id,
      },
      text: message?.message_text,
      createdAt: new Date(message?.createdat),
    }));

    setMessages(formattedMessages);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getChatMessage();
    };

    fetchData();
    const intervalId = setInterval(fetchData, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const renderBubble = (props) => {
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          right: {
            // backgroundColor: "#2196F3",
            marginRight: 10,
            cursor: "pointer",
          },
        }}
      />
    );
  };

  const renderSend = (props) => {
    return (
      <Send {...props}>
        <View>
          <FontAwesome
            name="send"
            size={24}
            color="#2196F3"
            style={{ marginBottom: 10, marginRight: 20 }}
          />
        </View>
      </Send>
    );
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <Header navigation={navigation} route={route} isHeader />
      <View style={{ flex: 1 }}>
        <GiftedChat
          messages={messages}
          onSend={(newMessages) => onSend(newMessages)}
          user={{
            _id: 1,
          }}
          renderBubble={(props) => {
            return renderBubble({
              ...props,
              position:
                props?.currentMessage?.sender_id === customers?.id
                  ? "right"
                  : "left",
            });
          }}
          placeholder="Type your message...."
          renderMessage={(props) => {
            return <Message {...props} renderAvatar={null} />;
          }}
          alwaysShowSend
          renderSend={renderSend}
          renderDay={(props) => {
            const currentDate =
              props?.currentMessage?.createdAt?.toDateString();
            const prevMessageIndex = props?.previousMessage
              ? props?.previousMessage?.createdAt?.toDateString()
              : null;

            if (currentDate !== prevMessageIndex) {
              return (
                <View style={styles.chatDayView}>
                  <Text style={styles.dayStyle}>{currentDate}</Text>
                </View>
              );
            }
            return null;
          }}
        />
      </View>
    </SafeAreaView>
  );
};

export default ChatScreen;
