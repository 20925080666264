import React, { useEffect } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector, useDispatch } from "react-redux";
import { Card, Title } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { setCategory } from "../components/redux/actions/prodActions";
import { getCategory } from "../actions";
import styles from "../components/style/styles.js";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import Button from "../components/Button";
import Header from "../components/core/Header";
import CoreFooter from "../components/core/CoreFooter";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
let _user;

const LandingCritera = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgLaunguage = useSelector((state) => state.prodReducer.languageData);
  const dispatch = useDispatch();
  const acceptList = orgDetail?.[0]?.approveText;
  const rejectList = orgDetail?.[0]?.rejectText;
  const selfServiceFlow = orgDetail?.[0]?.selfServiceFlow;

  const customPageList = orgDetail[0]?.customPages
    ? JSON.parse(orgDetail[0]?.customPages)
    : {};

  useEffect(async () => {
    const getUser = await AsyncStorage.getItem("customer");
    _user = JSON.parse(getUser);
    getCategory()
      .then(({ data }) => {
        dispatch(setCategory(data));
      })
      .catch((error) => { });
  }, []);

  const handleNext = () => {
    let pageUrl = "Additem"
    if (orgDetail[0]?.urlPath === 'sandqvist') {
      // if (customPageList?.isHideItem && customPageList?.isHideItemCart && customPageList?.isHideOption && customPageList?.isHideSearch && customPageList?.isHidePrice) {
      //   pageUrl = "AddItemProductType"
      // } else
      if (customPageList?.isHideItem && customPageList?.isHideItemCart && customPageList?.isHideOption && customPageList?.isHideSearch) {
        pageUrl = "AddItemProductType"
      } else if (customPageList?.isHideItem && customPageList?.isHideItemCart && customPageList?.isHideOption) {
        pageUrl = "AddItemSearch"
      } else if (customPageList?.isHideItem && customPageList?.isHideItemCart) {
        pageUrl = "Additemoption"
      } else if (customPageList?.isHideItem) {
        pageUrl = "AddItemCart"
      } else {
        pageUrl = "Additem"
      }
    }
    navigation.navigate(selfServiceFlow ? "AddItemProductType" : pageUrl, {
      organisationId: pickOrg,
    });
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <Header navigation={navigation} route={route} isHeader />
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View
            style={[
              styles.contentHeader,
              { marginBottom: 0, marginHorizontal: 45 },
            ]}
          >
            <View>
              <CoreText
                textTitle={
                  orgLaunguage?.landing_title ||
                  "Riktlinjer för inlämning av begagnade kläder"
                }
                style={[
                  styles.mainTitle,
                  {
                    marginTop: 24,
                    color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                    textAlign: "center",
                  },
                ]}
                fontType={MontserratMedium}
              />
              <CoreText
                textTitle={
                  orgLaunguage?.landing_subtitle ||
                  "För att vara säker på att vi kommer acceptera dina kläder behöver du följa dessa riktlinjer:"
                }
                style={[
                  styles.subTitle,
                  { paddingTop: 15, marginBottom: 30, textAlign: "center" },
                ]}
                fontType={MontserratExtraLight}
              />
            </View>
          </View>

          <View style={[styles.cardContainer, { marginTop: 0 }]}>
            <View style={{ marginBottom: 30 }}>
              <Card style={styles.cardStyle}>
                <Title>
                  <CoreText
                    textTitle="Vi tar emot"
                    style={[styles.headerTitle]}
                    fontType={MontserratBold}
                  />
                </Title>
                {acceptList?.length > 0 ? (
                  acceptList?.map((item, index) => (
                    <TouchableOpacity
                      key={index}
                      onPress={() =>
                        navigation.navigate("LandingCriteraAccept", {
                          approveData: item.approveGarmentType,
                          organisationId: pickOrg,
                        })
                      }
                    >
                      <Card.Title
                        subtitleNumberOfLines={2}
                        style={[
                          styles.cardTitleStyle,
                          { alignItems: "flex-start" },
                        ]}
                        leftStyle={[styles.leftStyle, { marginTop: -2 }]}
                        title={
                          <CoreText
                            textTitle={
                              item.approveInstruction ||
                              "Kläder från märken som vi säljer i våra butiker"
                            }
                            style={[
                              styles.subtitleStyle,
                              {
                                textAlign: "center",
                                display: "flex",
                                overflow: "visible",
                              },
                            ]}
                            fontType={MontserratRegular}
                          />
                        }
                        titleNumberOfLines={2}
                        left={() => (
                          <Ionicons
                            name="checkmark-circle-sharp"
                            size={18}
                            color={Colors.GREEN}
                          />
                        )}
                      />
                    </TouchableOpacity>
                  ))
                ) : (
                  <View />
                )}
              </Card>
            </View>
            <View>
              <Card
                style={styles.cardStyle}
              // onPress={() => navigation.navigate("LandingCriteraNotAccept")}
              >
                <Title>
                  <CoreText
                    textTitle="Vi tar inte emot"
                    style={[styles.headerTitle]}
                    fontType={MontserratBold}
                  />
                </Title>
                {rejectList?.length > 0 ? (
                  rejectList?.map((item, index) => (
                    <View key={index}>
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate("LandingCriteraNotAccept", {
                            rejectInstruction: item.rejectInstruction,
                            image: item.image,
                            detail: item.detail,
                            organisationId: pickOrg,
                          })
                        }
                      >
                        <Card.Title
                          subtitleNumberOfLines={2}
                          style={[
                            styles.cardTitleStyle,
                            { alignItems: "flex-start" },
                          ]}
                          leftStyle={[styles.otherLeftStyle, { marginTop: -2 }]}
                          title={
                            <CoreText
                              textTitle={
                                item.rejectInstruction ||
                                "Trasiga, fläckiga kläder"
                              }
                              style={[styles.subtitleStyle]}
                              fontType={MontserratRegular}
                            />
                          }
                          titleNumberOfLines={2}
                          left={() => (
                            <AntDesign
                              name="closecircle"
                              size={16}
                              color={Colors.RED}
                            />
                          )}
                        />
                      </TouchableOpacity>
                    </View>
                  ))
                ) : (
                  <View />
                )}
              </Card>
            </View>
          </View>
        </View>
      </ScrollView>
      <Button
        btnTitle={orgLaunguage?.landing_button || "Fortsätt"}
        style={[
          styles.footerButtonSection,
          {
            marginHorizontal: 20,
            marginBottom: 30,
          },
        ]}
        onPress={handleNext}
        lineHeight={30}
      />
      <CoreFooter />
    </SafeAreaView>
  );
};
export default LandingCritera;
