import React from "react";
import { View, Platform, SafeAreaView, StatusBar } from "react-native";
import { useSelector } from "react-redux";
import { Card, TouchableRipple } from "react-native-paper";
import styles from "../components/style/styles.js";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Header from "../components/core/Header";
import Colors from "../constants/Colors";
import CoreFooter from "../components/core/CoreFooter";

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const addItem = [
  {
    title: "Dam",
  },
  {
    title: "Herr",
  },
  {
    title: "Barn",
  },
];

const AddItemCart = ({ navigation, route }) => {
  const pickOrg = route.params.organisationId;
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgLaunguage = useSelector((state) => state.prodReducer.languageData);

  const customPageList = orgDetail[0]?.customPages
    ? JSON.parse(orgDetail[0]?.customPages)
    : {};

  const handleNext = (item) => {
    let pageUrl = "Additemoption"
    if (orgDetail[0]?.urlPath === 'sandqvist') {
      // if (customPageList?.isHideOption && customPageList?.isHideSearch && customPageList?.isHidePrice) {
      //   pageUrl = "AddItemProductType"
      // } else
      if (customPageList?.isHideOption && customPageList?.isHideSearch) {
        pageUrl = "AddItemProductType"
      } else if (customPageList?.isHideOption) {
        pageUrl = "AddItemSearch"
      } else {
        pageUrl = "Additemoption"
      }
    }
    navigation.navigate(pageUrl, {
      gender: item.title,
      organisationId: pickOrg,
    });
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <View style={styles.Landingcontainer}>
        <Header
          navigation={navigation}
          route={route}
          isHeaderItem
          progress={0.25}
          steps={1}
        />
        <ScrollView>
          <View
            style={
              (styles.contentHeader, { marginBottom: 30, marginHorizontal: 45 })
            }
          >
            <View style={{ textAlign: "center" }}>
              <CoreText
                textTitle={
                  orgLaunguage?.additemcart_title ||
                  "Vilken kategori tillhör plagget?"
                }
                style={[
                  styles.mainTitle,
                  {
                    marginTop: 24,
                    color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                    textAlign: "center",
                  },
                ]}
                fontType={MontserratMedium}
              />
            </View>
          </View>

          <View style={[styles.cardContainertitle, { marginBottom: 30 }]}>
            {addItem.map((item, index) => (
              <View style={[styles.cardBody, { marginBottom: 15 }]} key={index}>
                <Card style={{ borderRadius: 12, shadowColor: "transparent" }}>
                  <TouchableRipple
                    // onPress={() =>
                    //   navigation.navigate("Additemoption", {
                    //     gender: item.title,
                    //     organisationId: pickOrg,
                    //   })
                    // }
                    onPress={() => handleNext(item)}
                    style={[styles.cardStyle]}
                    rippleColor="rgba(0, 0, 0, .32)"
                  >
                    <CoreText
                      textTitle={item.title}
                      style={styles.headerTitle}
                      fontType={MontserratBold}
                    />
                  </TouchableRipple>
                </Card>
              </View>
            ))}
          </View>
        </ScrollView>
        <CoreFooter />
      </View>
    </SafeAreaView>
  );
};
export default AddItemCart;
