import React, { useState } from "react";
import { Platform, StyleSheet } from "react-native";
import { Entypo } from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";

const Picker = ({ items, isLanguage }) => {
  let coutryRef = React.createRef();
  const [pickerData, setPickerData] = useState(undefined);

  return Platform.OS === "web" ? (
    <RNPickerSelect
      placeholder={{
        label: isLanguage ? "Select Language" : "Select Country",
      }}
      items={items}
      onValueChange={(value) => {
        // let pickValue =
        //   value.length > 22 ? value.substring(0, 22 - 3) + "..." : value;
        // console.log("=====", pickValue);
        setPickerData(value);
      }}
      useNativeAndroidPickerStyle={false}
      style={{
        ...pickerSelectStyles,
        iconContainer: {
          top: Platform.OS === "android" ? 8 : 10,
          right: 5,
        },
        placeholder: {
          color: "#000",
        },
      }}
      ellipsizeMode="tail"
      value={pickerData}
      ref={(el) => {
        coutryRef = el;
      }}
      textInputProps={{ selection: { start: 0 }, numberOfLines: 1 }}
    />
  ) : (
    <RNPickerSelect
      placeholder={{
        label: isLanguage ? "Select Language" : "Select Country",
      }}
      items={items}
      onValueChange={(value) => {
        // let pickValue =
        //   value.length > 22 ? value.substring(0, 22 - 3) + "..." : value;
        // console.log("=====", pickValue);
        setPickerData(value);
      }}
      useNativeAndroidPickerStyle={false}
      style={{
        ...pickerSelectStyles,
        iconContainer: {
          top: Platform.OS === "android" ? 8 : 10,
          right: 5,
        },
        placeholder: {
          color: "#000",
        },
      }}
      ellipsizeMode="tail"
      value={pickerData}
      ref={(el) => {
        coutryRef = el;
      }}
      textInputProps={{ selection: { start: 0 }, numberOfLines: 1 }}
      Icon={() => {
        return <Entypo name="chevron-small-down" size={24} color="black" />;
      }}
    />
  );
};

export default Picker;

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 13,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: "gray",
    color: "black",
    paddingRight: 30,
  },
  inputAndroid: {
    flex: 1,
    flexDirection: "row",
    fontSize: 13,
    paddingHorizontal: 5,
    borderWidth: 0.5,
    borderColor: "#000",
    color: "black",
    paddingRight: 25,
    height: 42,
  },
});
