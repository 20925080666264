import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Image,
  useWindowDimensions,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
  Text,
  KeyboardAvoidingView,
  StyleSheet,
  FlatList,
  LogBox,
  Dimensions,
  TouchableOpacity,
  Animated,
  Easing,
  TextInput,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AntDesign, SimpleLineIcons } from "@expo/vector-icons";
import RNPickerSelect from "react-native-picker-select";
import PropTypes from "prop-types";
import Svg, { Path } from "react-native-svg";
import { useSelector } from "react-redux";
import { getProductDetails } from "../actions";
import styles from "../components/style/styles.js";
import CoreText from "../components/core/CoreText";
import CoreImage from "../components/core/CoreImage";
import Footer from "../components/Footer";
import CoreLoader from "../components/core/CoreLoader";

const AssistantMedium = require("../assets/Font/Assistant-Medium.ttf");
const AssistantRegular = require("../assets/Font/Assistant-Regular.ttf");

const Collection = ({
  height,
  borderRadius,
  fontSize,
  backgroundColor,
  placeholderTextColor,
  searchIconSize,
  searchIconColor,
  focusAfterOpened,
  shadowColor,
  placeholder,
  animationSpeed,
  onOpened,
  onClosing,
}) => {
  const backIconStyle = useWindowDimensions().width;
  const [email, setEmail] = useState("");

  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  let orgName;
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const [collection, setCollection] = useState([]);
  const [pickerData, setPickerData] = useState(undefined);
  const [isFilter, setIsFilter] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [message, setMesssage] = useState("");
  const { width } = Dimensions.get("window");
  const itemWidth = (width - 15) / 2;
  const refSearchBox = useRef();
  const refTextInput = useRef();
  const [isOpen, setIsOpen] = useState(false);
  // const [searchIconColor, setSearchIconColor] = useState("#000");
  const [screenWidth, setScreenWidth] = useState(0);
  const [textInputAnimated, setTextInputAnimated] = useState(
    new Animated.Value(0)
  );
  const [parentViewWidthAnimated, setParentViewWidthAnimated] = useState(
    new Animated.Value(height)
  );
  const [isScaled, setIsScaled] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);

  useEffect(async () => {
    orgName = await AsyncStorage.getItem("organisation")
    setIsLoad(true);
    await getProductDetails(orgName)
      .then((resp) => {
        setCollection(resp.data.Items);
      })
      .then(() => setIsLoad(false))
      .catch((error) => { });
  }, []);

  useEffect(() => {
    closeSearchBox();
  }, []);

  useEffect(() => {
    LogBox.ignoreAllLogs();
  }, []);

  const filterCategory = collection.filter(
    (item) => item.gender === pickerData
  );

  const hadnleSearch = (e) => {
    setIsFilter(false);
    setIsSearch(true);
    const filteredName = collection.filter((item) => {
      return (
        item.category.toLowerCase().match(e.trim()) ||
        item.category.toUpperCase().match(e.trim())
      );
    });
    if (e.trim() === "") {
      setMesssage("");
      setSearchFilter(filteredName);
    } else if (filteredName.length === 0) {
      setMesssage("No Match Found");
    } else {
      setSearchFilter(filteredName);
    }
  };

  const onLayout = (e) => {
    const { width } = e.nativeEvent.layout;
    setScreenWidth(width);
  };

  const onOpening = () => {
    // setSearchIconColor("#000");
    setTimeout(() => {
      setIsOpen(true);
    }, 300);
  };

  const onClosed = () => {
    // setSearchIconColor("#000");
    setIsOpen(false);
  };

  const searchIcon = () => {
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 416 416"
        width={searchIconSize}
        height={searchIconSize}
      >
        <Path
          fill="none"
          stroke={"#000"}
          strokeMiterlimit="10"
          strokeWidth="32"
          d="M173.09 16a157.09 157.09 0 10157.09 157.09h0A157.1 157.1 0 00173.09 16z"
        />
        <Path
          fill="none"
          stroke={"#000"}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="32"
          d="M290.29 290.29L400 400"
        />
      </Svg>
    );
  };

  const open = () => {
    onOpening && onOpening();

    Animated.timing(textInputAnimated, {
      toValue: 1,
      duration: animationSpeed[0],
      easing: Easing.linear,
    }).start(() => {
      setTimeout(() => {
        setIsScaled(true);

        Animated.timing(parentViewWidthAnimated, {
          toValue: screenWidth,
          duration: animationSpeed[1],
          easing: Easing.linear,
        }).start(() => {
          onOpened && onOpened();
          if (focusAfterOpened) refTextInput.focus();
        });
      }, 125);
    });
  };

  const close = () => {
    onClosing && onClosing();

    Animated.timing(parentViewWidthAnimated, {
      toValue: height,
      duration: animationSpeed[1],
      easing: Easing.linear,
    }).start(() => {
      setIsScaled(false);

      setTimeout(() => {
        Animated.timing(textInputAnimated, {
          toValue: 0,
          duration: animationSpeed[0],
          easing: Easing.linear,
        }).start(() => {
          onClosed && onClosed();
        });
      }, 125);
    });
  };

  const closeSearchBox = () => {
    refSearchBox?.current?.close();
    close();
    setIsOpen(false);
  };

  const myCollection = isFilter
    ? pickerData === "Filter"
      ? collection
      : filterCategory
    : isSearch
      ? searchFilter
      : collection;

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <ScrollView nestedScrollEnabled={true}>
          <View style={styles.collectionContainer}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <View style={{ marginVertical: 12 }}>
                <SimpleLineIcons name="menu" size={20} color="black" />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  position: "relative",
                }}
              >
                <View
                  onLayout={onLayout}
                  style={[
                    styles.searchbaropen,
                    { width: width - 100, flexDirection: "row" },
                  ]}
                >
                  <Animated.View
                    style={[
                      {
                        transform: [
                          { scaleX: textInputAnimated },
                          { scaleY: textInputAnimated },
                        ],
                        opacity: textInputAnimated,
                        width: parentViewWidthAnimated,
                        marginLeft: "auto",
                      },
                    ]}
                  >
                    <TextInput
                      ref={refTextInput}
                      placeholderTextColor={
                        isScaled ? placeholderTextColor : "transparent"
                      }
                      placeholder={"Searchbar...."}
                      style={[
                        {
                          shadowColor: shadowColor,
                          backgroundColor: backgroundColor,
                          height: height,
                          borderRadius: borderRadius,
                          fontSize: fontSize,
                          paddingLeft: height,
                        },
                      ]}
                      onChangeText={(e) => hadnleSearch(e)}
                    />

                    {isScaled ? (
                      <View
                        style={[
                          styles.inputSearchIcon,
                          { width: height, height: height },
                        ]}
                      >
                        {searchIcon()}
                      </View>
                    ) : null}
                  </Animated.View>

                  {isScaled ? null : (
                    <TouchableOpacity
                      onPress={open}
                      style={[
                        styles.inputClosedSearchIcon,
                        { width: height, height: height },
                      ]}
                    >
                      {searchIcon()}
                    </TouchableOpacity>
                  )}
                </View>
                <View style={{ marginVertical: 10 }}>
                  <Image
                    source={require("../assets/images/Vector.png")}
                    style={styles.bagIcon}
                  />
                  <View style={styles.bagTotalItem}>
                    <CoreText
                      style={styles.bagTotalItemNumber}
                      textTitle={collection?.length}
                      fontType={AssistantRegular}
                    />
                  </View>
                </View>
              </View>
            </View>
            {isOpen && (
              <View style={styles.closebtn}>
                <TouchableOpacity onPress={closeSearchBox}>
                  <AntDesign name="closecircleo" size={20} color="#000" />
                </TouchableOpacity>
              </View>
            )}
          </View>

          <View style={[styles.horizontalRule, { marginTop: 2.5 }]} />
          <View style={[styles.Landingcontainer, { paddingBottom: 0 }]}>
            <View style={styles.containerBody}>
              <View style={[styles.FlexContainer, { width: 400 }]}>
                <CoreImage imgUrl={orgDetail?.[0]?.logoType} />
              </View>
            </View>
            <View style={{ marginHorizontal: 12 }}>
              <View style={styles.contentHeaderBody}>
                <CoreText
                  textTitle="Varukorg"
                  style={styles.collectionTitle}
                  fontType={AssistantRegular}
                />
              </View>
              <View style={styles.collectionproduct}>
                <View style={{ marginTop: -10 }}>
                  <RNPickerSelect
                    placeholder={{
                      label: "Filter",
                      value: "Filter",
                    }}
                    items={[
                      {
                        label: "Dam",
                        value: "Dam",
                      },
                      {
                        label: "Herr",
                        value: "Herr",
                      },
                      {
                        label: "Annat",
                        value: "Annat",
                      },
                    ]}
                    onValueChange={(value) => {
                      setPickerData(value);
                      setIsFilter(true);
                      setIsSearch(false);
                    }}
                    useNativeAndroidPickerStyle={false}
                    style={{
                      ...pickerSelectStyles,
                      iconContainer: {
                        top: Platform.OS === "android" ? 11 : 10,
                        left: 5,
                      },
                      placeholder: {
                        color: "#000",
                      },
                    }}
                    ellipsizeMode="tail"
                    value={pickerData}
                    textInputProps={{
                      selection: { start: 0 },
                      numberOfLines: 1,
                    }}
                    Icon={() => {
                      return (
                        Platform.OS !== "web" && (
                          <Image
                            style={{ width: 20, height: 20 }}
                            source={require("../assets/images/filter.jpg")}
                          />
                        )
                      );
                    }}
                  />
                </View>
                <Text>
                  <CoreText
                    textTitle={`${isFilter ? filterCategory?.length : collection?.length
                      } produkter`}
                    style={styles.filterTitle}
                    fontType={AssistantRegular}
                  />
                </Text>
              </View>
              {isLoad ? (
                <CoreLoader style={{ flex: 1 }} />
              ) : message ? (
                <View>
                  <CoreText
                    textTitle={message}
                    style={styles.titleStyle}
                    fontType={AssistantMedium}
                  />
                </View>
              ) : (
                <FlatList
                  nestedScrollEnabled={true}
                  numColumns={2}
                  contentInset
                  data={myCollection}
                  style={{ flex: 1 }}
                  columnWrapperStyle={{
                    flex: 0.5,
                    justifyContent: "space-between",
                  }}
                  renderItem={({ item, index }) => (
                    <View
                      key={index}
                      style={[
                        styles.collectionProduct,
                        {
                          flex: 1,
                          minWidth: itemWidth,
                          maxWidth: itemWidth,
                          height: index === collection.length - 1 ? 220 : 266,
                        },
                      ]}
                    >
                      <View>
                        <Image
                          style={{ width: 170, height: 170 }}
                          source={{ uri: item.photo }}
                        />
                      </View>
                      <View style={{ paddingTop: 4 }}>
                        <CoreText
                          style={styles.filterTitle}
                          textTitle={item.category}
                          fontType={AssistantRegular}
                        />
                      </View>
                      <View>
                        <CoreText
                          style={styles.pricelist}
                          textTitle={`${item.price_interval} SEK`}
                          fontType={AssistantRegular}
                        />
                      </View>
                    </View>
                  )}
                />
              )}
            </View>
          </View>
          <Footer />
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
export default Collection;

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 13,
    paddingVertical: 12,
    paddingHorizontal: 10,
    color: "black",
    paddingRight: 30,
  },
  inputAndroid: {
    flex: 1,
    flexDirection: "row",
    fontSize: 13,
    // paddingVertical: 12,
    // paddingHorizontal: 5,
    paddingLeft: 30,
    color: "black",
    // paddingRight: 25,
    height: 42,
    width: 100,
  },
});

Collection.defaultProps = {
  height: 48,
  borderRadius: 48,
  searchIconColor: "#555555",
  searchIconSize: 20,
  focusAfterOpened: false,
  placeholderTextColor: "#555555",
  fontSize: 16,
  backgroundColor: "rgba(255,255,255,0.70)",
  shadowColor: "#fff",
  animationSpeed: [200, 250],
};

Collection.propTypes = {
  height: PropTypes.number,
  borderRadius: PropTypes.number,
  fontSize: PropTypes.number,
  backgroundColor: PropTypes.string,
  placeholderTextColor: PropTypes.string,
  searchIconSize: PropTypes.number,
  searchIconColor: PropTypes.string,
  focusAfterOpened: PropTypes.bool,
  shadowColor: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  animationSpeed: PropTypes.array,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  onOpening: PropTypes.func,
  onClosing: PropTypes.func,
};
