import React from "react";
import { ActivityIndicator } from "react-native";
import Colors from "../../constants/Colors";
import { useSelector } from "react-redux";

function CoreLoader({ style, isButton }) {
  const orgDetail = useSelector((state) => state.prodReducer.products);

  return (
    <ActivityIndicator
      color={
        isButton ? Colors.WHITE : orgDetail?.[0]?.primaryColor || Colors.BROWN
      }
      size={isButton ? "small" : "large"}
      style={style}
    />
  );
}

export default CoreLoader;
