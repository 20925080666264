import React, { useEffect, useState } from "react";
import { View, SafeAreaView } from "react-native";
import CoreText from "../components/core/CoreText";
import styles from "../components/style/styles";
import { useSelector } from "react-redux";
import {
  Fontisto,
  Octicons,
  AntDesign,
  SimpleLineIcons,
  Entypo,
  Ionicons,
} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Grid, Col } from "react-native-easy-grid";
import { signOut } from "../components/core/CoreSignOut";
import { ScrollView } from "react-native-gesture-handler";
import { Card, TouchableRipple } from "react-native-paper";
import CoreImage from "../components/core/CoreImage";
import { ReadyToSaleStatus } from "../sharedLib/sourcingItemStatus.js";
import Colors from "../constants/Colors";
import { getProductDetails } from "../actions";

const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

let orgName;
const FooterHumburderMenu = ({ navigation, route, setSideMenuVisible }) => {
  const [totalSourceItems, setTotalSourceItems] = useState();
  const pickOrg = route?.params?.organisationId;
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const switchItemData = orgDetail[0]?.theme?.setting[0]?.value === "true";
  const customers = useSelector((state) => state.prodReducer.customerDetail);
  const poolData = useSelector((state) => state.prodReducer.userPool);
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);

  const customOptionsData = orgSetting?.customOptions
    ? JSON.parse(orgSetting?.customOptions)
    : {};

  useEffect(async () => {
    orgName = await AsyncStorage.getItem("organisation");
    getProductDetails(orgName).then((resp) => {
      setTotalSourceItems(resp?.data);
    });
  }, []);

  const totalItem = totalSourceItems?.filter(
    (item) => item.sale_status === ReadyToSaleStatus.Sold
  );

  const totalComission = totalItem
    ?.filter((item) => item.sale_status === ReadyToSaleStatus.Sold)
    ?.reduce((sum, item) => item.sale_status && sum + item.commission, 0);

  const menuClick = (button) => {
    switch (button) {
      case "withdrawal":
        setSideMenuVisible(false);
        navigation.navigate("CommissionWithdraw", {
          organisationId: pickOrg,
          totalItem: totalItem?.length,
          allComission: totalComission,
        });
        break;

      case "contactus":
        setSideMenuVisible(false);
        navigation.navigate("ContactUs", {
          organisationId: pickOrg,
          totalItem: totalItem?.length,
          allComission: totalComission,
        });
        break;

      case "home":
        signOut(poolData);
        setSideMenuVisible(false);
        navigation.navigate("HomePage", {
          organisationId: pickOrg,
        });
        break;

      case "chat":
        setSideMenuVisible(false);
        navigation.navigate("ChatScreen", {
          organisationId: pickOrg,
        });
        break;

      case "mypage":
        setSideMenuVisible(false);
        if (orgDetail?.[0]?.cashierIntegration == "boxnet") {
          navigation.navigate("SoldClothBagCollectionIntegration", {
            organisationId: pickOrg,
          });
        } else {
          navigation.navigate("SoldClothBagCollection", {
            organisationId: pickOrg,
          });
        }

        break;

      case "signout":
        signOut(poolData);
        setSideMenuVisible(false);
        navigation.navigate("HomePage", {
          organisationId: pickOrg,
        });
        break;

      default:
        break;
    }
  };
  const addHeaderMenu = [];
  addHeaderMenu.push({
    title: "Hem",
    iconImage: <AntDesign name="contacts" size={22} color="#fff" />,
    isClick: true,
    button: "home",
  });

  if (customers?.isLogin) {
    addHeaderMenu.push(
      {
        title: "Min sida",
        iconImage: <Fontisto name="nav-icon-list-a" size={18} color="#fff" />,
        isClick: true,
        button: "mypage",
      },
      {
        title: "Ta ut pengar",
        iconImage: <Octicons name="credit-card" size={22} color="#fff" />,
        isClick: true,
        button: "withdrawal",
      },
    );
    if (customOptionsData?.isShowChat) {
      addHeaderMenu.push({
        title: "Chat",
        iconImage: (
          <Ionicons name="chatbubble-ellipses-outline" size={24} color="#fff" />
        ),
        isClick: true,
        button: "chat",
      });
    }
  }
  addHeaderMenu.push({
    title: "Kontakta oss",
    iconImage: <AntDesign name="contacts" size={22} color="#fff" />,
    isClick: true,
    button: "contactus",
  });

  if (customers?.isLogin) {
    addHeaderMenu.push({
      title: "Logga ut",
      iconImage: <SimpleLineIcons name="logout" size={22} color="#fff" />,
      isClick: true,
      button: "signout",
    });
  }
  return (
    <SafeAreaView
      style={{
        flex: 1,
      }}
    >
      <ScrollView>
        <View style={{ paddingHorizontal: 20, paddingTop: 25 }}>
          <CoreImage imgUrl={orgDetail?.[0]?.logoType} />
          <View
            style={[
              styles.cardContainertitle,
              { marginHorizontal: 0, marginTop: 24 },
            ]}
          >
            <View style={styles.cardBody}>
              <Card
                style={{
                  borderRadius: 12,
                  shadowColor: "transparent",
                  marginBottom: 16,
                }}
              >
                <TouchableRipple
                  style={[
                    styles.addHeaderButton,
                    switchItemData && styles.buttonNone,
                    {
                      backgroundColor:
                        orgDetail?.[0]?.primaryColor || Colors.BROWN,
                    },
                  ]}
                  onPress={() => {
                    setSideMenuVisible(false);
                    if (customers?.isLogin) {
                      navigation.navigate("LandingCritera", {
                        organisationId: pickOrg,
                      });
                    } else {
                      var org = pickOrg;
                      navigation.navigate("Signup", { org });
                    }
                  }}
                >
                  <Grid>
                    <Col size={2}>
                      <Entypo name="plus" size={24} color="#fff" />
                    </Col>
                    <Col size={10}>
                      <CoreText
                        textTitle={`Sälj kläder`}
                        style={[styles.headerTitle, { color: "white" }]}
                        fontType={MontserratBold}
                      />
                    </Col>
                  </Grid>
                </TouchableRipple>
              </Card>
            </View>

            <View style={styles.cardBody}>
              {addHeaderMenu.map((item, index) => {
                return (
                  item !== false && (
                    <Card
                      style={{
                        borderRadius: 12,
                        shadowColor: "transparent",
                        marginBottom: 16,
                      }}
                      key={index}
                    >
                      <TouchableRipple
                        style={[
                          styles.headerCardStyle,
                          {
                            backgroundColor:
                              orgDetail?.[0]?.primaryColor || Colors.BROWN,
                          },
                        ]}
                        rippleColor="rgba(0, 0, 0, .32)"
                        onPress={() => {
                          item.isClick && menuClick(item.button);
                        }}
                      >
                        <Grid>
                          <Col size={2}>{item.iconImage}</Col>
                          <Col size={10}>
                            <CoreText
                              textTitle={item.title}
                              style={[
                                styles.headerTitle,
                                { color: Colors.WHITE },
                              ]}
                              fontType={MontserratBold}
                            />
                          </Col>
                        </Grid>
                      </TouchableRipple>
                    </Card>
                  )
                );
              })}
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default FooterHumburderMenu;
