import React from "react";
import styles from "../components/style/styles.js";
import { View } from "react-native";
import { TextInput } from "react-native-paper";
import CoreText from "../components/core/CoreText";
import RNPickerSelect from "react-native-picker-select";

const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");
const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");

const OrgTemplate = ({
  setDescription,
  descriptionData,
  setSelectValue,
  selectValue,
  organisationSourceItem,
}) => {
  const description = (
    <View style={{ marginTop: 15 }}>
      <CoreText
        style={{
          fontSize: 12,
          fontWeight: 600,
          marginBottom: 10,
        }}
        textTitle="Desctiptions"
        fontType={MontserratLight}
      />
      <TextInput
        label={
          <CoreText
            textTitle="Type Desctiptions"
            style={[styles.input]}
            fontType={MontserratRegular}
            isInput
          />
        }
        style={[styles.inputFiledstyle]}
        value={descriptionData}
        keyboardType="default"
        placeholderTextColor="#878787"
        underlineColor="transparent"
        activeUnderlineColor="transparent"
        maxLength={200}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
    </View>
  );
  const vat = (
    <View style={{ marginTop: 10 }}>
      <RNPickerSelect
        placeholder={{
          label: "Select VAT Number",
        }}
        items={getVatData}
        onValueChange={(value) => {
          setSelectValue(value);
        }}
        useNativeAndroidPickerStyle={false}
        value={selectValue}
        pickerProps={{
          style: {
            padding: 10,
            fontSize: 15,
            borderWidth: 1,
            borderColor: "#DCDCE0",
            borderRadius: 5,
          },
        }}
      />
    </View>
  );
  const allSourceItemFields = {
    Description: description,
    Vat: vat,
  };
  const sorcingItemData = organisationSourceItem?.map((item, index) => {
    return (
      <View style={{ marginHorizontal: 20 }} key={index}>
        {allSourceItemFields[item]}
      </View>
    );
  });
  return <>{sorcingItemData}</>;
};
export default OrgTemplate;

const getVatData = [
  {
    label: "0%",
    value: 0,
  },
  {
    label: "6%",
    value: 6,
  },
  {
    label: "12%",
    value: 12,
  },
  {
    label: "25%",
    value: 25,
  },
];
