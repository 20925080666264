import React, { useState, useEffect } from "react";
import { View, Platform, Dimensions, Text } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import Colors from "../../constants/Colors";
import styles from "../../components/style/styles.js";
import CoreImage from "../../components/core/CoreImage";
import { AntDesign, Ionicons, FontAwesome } from "@expo/vector-icons";
import { ProgressBar } from "react-native-paper";
import CoreText from "../../components/core/CoreText";
import Modal from "react-native-modal";
import FooterHumburderMenu from "../../screens/FooterHumburderMenu";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setLanguageData, setOrganizationSettings } from "../../components/redux/actions/prodActions";
import { getOrganisationSettingData } from "../../actions";
import { Dropdown } from "react-native-element-dropdown";

const MontserratRegular = require("../../assets/Font/Montserrat-Regular.ttf");

const Header = ({
  navigation,
  isHeaderItem,
  isHeader,
  progress,
  steps,
  isHomePage,
  route,
}) => {
  const [sideMenuVisible, setSideMenuVisible] = useState(false);
  const [language, setLanguage] = useState("sw");
  const [organizationLaunguage, setOrganizationLaunguage] = useState();
  const { width } = Dimensions.get("window");
  const dispatch = useDispatch();

  useEffect(async () => {
    const organisationId = await AsyncStorage.getItem("organisation");
    getOrganisationSettingData(organisationId)
      .then((resp) => {
        dispatch(setOrganizationSettings(resp?.data[0]));
        setOrganizationLaunguage(
          resp?.data[0] ? JSON.parse(resp?.data[0]?.language) : {}
        );
      })
      .catch((error) => { });
  }, []);

  const toggleSideMenu = () => {
    setSideMenuVisible(true);
  };
  const orgDetail = useSelector((state) => state.prodReducer.products);

  useEffect(async () => {
    if (language) {
      const getAllOrgLaunguage = organizationLaunguage?.[language];
      dispatch(setLanguageData(getAllOrgLaunguage));
    }
  }, [language]);

  return (
    <>
      <View>
        <Modal
          isVisible={sideMenuVisible}
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInTiming={500}
          animationOutTiming={500}
          backdropOpacity={0.6}
          onBackdropPress={() => setSideMenuVisible(false)}
          style={{
            marginRight: 0,
            marginLeft: "auto",
            marginVertical: 0,
            width: width * 0.8,
            backgroundColor: "#fff",
          }}
        >
          <FooterHumburderMenu
            setSideMenuVisible={setSideMenuVisible}
            navigation={navigation}
            route={route}
          />
        </Modal>
      </View>
      <View
        style={
          Platform?.OS === "web"
            ? {
              position: "sticky",
              top: 0,
              right: 0,
              left: 0,
              backgroundColor:
                orgDetail?.[0]?.theme?.headerTopBgColor || "#F9F6F1",
              zIndex: 10,
              borderBottomWidth: 1,
              borderBottomColor: "rgba(58, 33, 4, 0.2)",
            }
            : {
              backgroundColor:
                orgDetail?.[0]?.theme?.headerTopBgColor || "#F9F6F1",
              borderBottomWidth: 1,
              borderBottomColor: "rgba(58, 33, 4, 0.2)",
            }
        }
      >
        {isHeader && (
          <View style={[styles.containerBody, { marginVertical: 10 }]}>
            {isHomePage ? (
              <View style={{ width: 22 }} />
            ) : (
              <AntDesign
                name="left"
                size={20}
                color={Colors.BLACK}
                onPress={() => navigation.goBack()}
              />
            )}

            <View
              style={[styles.FlexContainer, { width: "100%", maxWidth: 225 }]}
            >
              <CoreImage imgUrl={orgDetail?.[0]?.logoType} />
            </View>
            <View>
              <Ionicons
                name="ios-menu-sharp"
                size={26}
                color="black"
                onPress={toggleSideMenu}
              />
            </View>
          </View>
        )}
        {isHeaderItem && (
          <View style={[styles.containerHeader]}>
            <AntDesign
              name="left"
              size={20}
              color={Colors.BLACK}
              onPress={() => navigation.goBack()}
            />
            <View style={[styles.progressContainer, styles.FlexContainer]}>
              <ProgressBar
                progress={progress}
                color={orgDetail?.[0]?.primaryColor || Colors.BROWN}
                style={[styles.progress]}
              />
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <CoreText
                textTitle={`${steps} av 6`}
                style={[styles.progressSteps, { marginRight: 20 }]}
                fontType={MontserratRegular}
              />
            </View>
          </View>
        )}
      </View>
    </>
  );
};

export default Header;

const getLaunguage = [
  {
    label: "SW",
    value: "sw",
  },
  {
    label: "EN",
    value: "en",
  },
  {
    label: "FR",
    value: "fr",
  },
  {
    label: "SP",
    value: "sp",
  },
];