import React from "react";
import {
  Text,
  TouchableOpacity,
  View,
  Image,
  useWindowDimensions,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
} from "react-native";
import { useSelector } from "react-redux";
import { Card } from "react-native-paper";
import { AntDesign } from "@expo/vector-icons";
import Button from "../components/Button";
import CoreImage from "../components/core/CoreImage";
import styles from "../components/style/styles.js";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const AddItemCancle = ({ navigation }) => {
  const backIconStyle = useWindowDimensions().width;
  const orgDetail = useSelector((state) => state.prodReducer.products);

  const addAllItems = [
    {
      cardtitle: "Tunnikor",
      subtitle: "229",
      name: "sek",
      Paragraph:
        "Alla tunikor som har köpts hos Indiska dom senaste 3 åren accepteras alltid, även rea-varor",
    },
    {
      cardtitle: "Klänningar",
      subtitle: "299",
      name: "sek",
      Paragraph: "Alla färger och mönster.",
    },
    {
      cardtitle: "Jeans shorts",
      subtitle: "99",
      name: "sek",
      Paragraph:
        "Alla jeans shorts med högmidja accepteras även från andra varumärken",
    },
  ];

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View style={styles.containerBody}>
            <AntDesign
              name="left"
              size={24}
              color={Colors.LIGHTEST_GRAY}
              onPress={() => navigation.goBack()}
            />
            <View style={[styles.FlexContainer, { width: 400 }]}>
              <CoreImage imgUrl={orgDetail?.[0]?.logoType} />
            </View>
          </View>
          <View style={styles.horizontalRole} />
          <View style={[styles.indiskatext, { marginBottom: 33 }]}>
            <View style={styles.contentHeaderBody}>
              <CoreText
                textTitle="Vi tar emot följande plagg"
                style={[styles.mainTitle, { marginTop: 40 }]}
                fontType={MontserratMedium}
              />
              <CoreText
                textTitle="Mode säljer i säsong, därför skiljer sig vad vi tar imot olika tider. Just nu tar vi bara imot"
                style={[styles.subTitle, { marginTop: 15 }]}
                fontType={MontserratRegular}
              />
            </View>
          </View>
          <View style={(styles.AddcardContainer, { paddingTop: 33 })}>
            {addAllItems.map((item, id) => (
              <Card style={styles.Maincard} key={id}>
                <Card.Content
                  style={[styles.cardContent, { paddingVertical: 16 }]}
                >
                  <View style={[styles.cardTitle]}>
                    <CoreText
                      textTitle={item.cardtitle}
                      style={[styles.cardHeader]}
                      fontType={MontserratBold}
                      isFrom
                    />
                    <View style={styles.cardsubtitle}>
                      <Text style={styles.cardsubtitleTextStyle}>
                        <CoreText
                          textTitle={item.subtitle}
                          fontType={MontserratBold}
                        />{" "}
                        <CoreText
                          textTitle={item.name}
                          style={[styles.sektext]}
                          fontType={MontserratMedium}
                        />
                      </Text>
                    </View>
                  </View>
                  <CoreText
                    textTitle={item.Paragraph}
                    style={styles.CardParagraph}
                    fontType={MontserratMedium}
                  />
                </Card.Content>
              </Card>
            ))}
          </View>
        </View>
      </ScrollView>
      <View style={styles.btnSection}>
        <Button
          btnTitle="Lägg till annat plagg"
          style={[
            {
              marginTop: 12,
            },
          ]}
          onPress={() => navigation.navigate("AddItemCart")}
          lineHeight={30}
        />
      </View>
    </SafeAreaView>
  );
};
export default AddItemCancle;
